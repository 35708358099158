import { FC, useCallback, useLayoutEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Auth, Hub } from "aws-amplify";

import { useAppDispatch } from "app/hooks";
import { FREE_ACCESS_PATHS } from "common/constants";
import { PAGES } from "common/PAGES";
import Login from "components/Login";
import ScrollTop from "components/common/ScrollTop";
import { fetchAsyncGetUsers } from "ducks/auth/slice";
import { useInitialHooks } from "hooks/initialHooks";

const App: FC = () => {
  const { initialize } = useInitialHooks();
  initialize();
  const dispatch = useAppDispatch();
  const [user, setUser] = useState(null);

  /** ログインユーザの情報を取得 */
  const getUser = useCallback(async () => {
    try {
      const userData = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      setUser(userData);
      if (window.location.pathname !== "/error") {
        dispatch(fetchAsyncGetUsers(userData.attributes.email));
      }
    } catch (e) {
      return console.log("Not signed in");
    }
  }, [dispatch]);

  /** 認証情報の制御 */
  useLayoutEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          getUser();
          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
      }
    });
    getUser();
  }, [getUser]);
  return (
    <div className="App">
      {user || FREE_ACCESS_PATHS.includes(window.location.pathname) ? (
        <BrowserRouter>
          <ScrollTop />
          <Routes>
            {Object.entries(PAGES).map(([key, PAGE]) => {
              return <Route key={key} path={PAGE.PATH} element={PAGE.CONTAINER} />;
            })}
          </Routes>
        </BrowserRouter>
      ) : (
        <Login />
      )}
    </div>
  );
};

export default App;
