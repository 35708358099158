import { FC, memo } from "react";
import { Auth } from "aws-amplify";
import { slide as Menu } from "react-burger-menu";
import { Link, useNavigate } from "react-router-dom";

import { useAppSelector } from "app/hooks";
import { PAGES } from "common/PAGES";
import { DEFAULT_THUMBNAIL, Q_PARAMS } from "common/constants";
import { PathOfHeader } from "components/Header";
import { selectUserInfo } from "ducks/auth/slice";
import { UserInfo } from "ducks/auth/type";

import "styles/Hamburger.css";

type Props = {
  paths: PathOfHeader[];
  currentPath: string;
};

/**
 * ハンバーガーメニュー
 * @param Props
 * @returns
 */
const Hamburger: FC<Props> = ({ paths, currentPath }) => {
  const navigate = useNavigate();

  const userInfo: UserInfo = useAppSelector(selectUserInfo);

  const singOut = async () => {
    await Auth.signOut();
    navigate(0);
  };

  const clickLogin = () => {
    const aptNum: string | null = new URLSearchParams(window.location.search).get(
      Q_PARAMS.APARTMENT_NUMBER
    );
    window.location.href = `${PAGES.MYPAGE_TOP.PATH}?${Q_PARAMS.APARTMENT_NUMBER}=${aptNum}`;
  };

  return (
    <ul className="text-base [&_li]:border-b-2">
      {userInfo.email ? (
        <Menu right customBurgerIcon={<img src={DEFAULT_THUMBNAIL} alt="thumbnail" />}>
          <li className="font-bold !border-none">ログイン中 : {userInfo.userName}</li>
          {paths.map((path: PathOfHeader, index: number) => {
            return (
              <li key={index}>
                {path.isSpa ? (
                  <Link
                    className={
                      currentPath === path.path ? "border-l-4 pointer-events-none" : "block"
                    }
                    to={path.qParams ? `${path.path}?${path.qParams}` : path.path}
                  >
                    {path.name}
                  </Link>
                ) : (
                  <a
                    className={
                      currentPath === path.path ? "border-l-4 pointer-events-none" : "block"
                    }
                    href={path.qParams ? `${path.path}?${path.qParams}` : path.path}
                  >
                    {path.name}
                  </a>
                )}
              </li>
            );
          })}
          <li onClick={singOut}>
            <p className="cursor-pointer">ログアウト</p>
          </li>
        </Menu>
      ) : (
        <Menu right customBurgerIcon={<img src={DEFAULT_THUMBNAIL} alt="thumbnail" />}>
          <li className="font-bold !border-none">ゲストユーザ</li>
          <li className="cursor-pointer" onClick={clickLogin}>
            ログインする
          </li>
        </Menu>
      )}
    </ul>
  );
};

export default memo(Hamburger);
