import { FC, useState } from "react";

import Layout from "components/Layout";
import Title from "components/common/Title";
import Loader from "components/common/Loader";
import ContentCard from "components/common/ContentCard";
import TwoColContents from "components/common/TwoColContents";
import Button from "components/common/Button";
import StartDateSelect from "components/trunk/StartDateSelect";
import PayCardBtn from "components/mypage/PayCardBtn";
import Tos from "components/trunk/Tos";
import IdImgsUploadBtn from "components/trunk/IdImgsUploadBtn";
import ImgsModal from "components/common/ImgsModal";

import { useTrunkApplication } from "hooks/trunkApplicationHooks";

type Props = {
  title: string;
};

/**
 * トランクルーム申込み確認画面
 * @param Props
 * @returns
 */
const Application: FC<Props> = ({ title }) => {
  const {
    userInfo,
    isLoading,
    rows,
    startDatedAt,
    idImgs,
    isOpenImgsModal,
    idImgSrcs,
    cardToken,
    setIsOpenImgsModal,
    setStartDatedAt,
    setCardToken,
    clickApply,
    clickBack,
    closeImgsModal,
  } = useTrunkApplication();

  const [isAgree, setIsAgree] = useState<boolean>(false);
  const checkTos = () => {
    setIsAgree(!isAgree);
  };

  return (
    <Layout title={title}>
      {isLoading ? (
        <Loader isActive={isLoading} />
      ) : (
        <>
          {rows.length > 0 && (
            <div className="pb-16">
              <Title>トランクルームの利用申し込み確認</Title>
              <ContentCard>
                <TwoColContents rows={rows} />
              </ContentCard>

              <div className="w-4/5 mx-auto mt-8 max-w-md">
                <StartDateSelect setDate={setStartDatedAt} />
              </div>

              <Tos isAgree={isAgree} checkTos={checkTos} />

              {!userInfo.isUploadedIdDoc && (
                <>
                  <div className="mt-8">
                    <IdImgsUploadBtn idImgs={idImgs} />
                  </div>

                  {idImgSrcs.length > 0 && (
                    <div className="text-center mt-4">
                      <p className="link-a-tag inline" onClick={() => setIsOpenImgsModal(true)}>
                        選択した本人確認書類を確認
                      </p>
                    </div>
                  )}
                </>
              )}

              {!userInfo.isAuthedCard && <PayCardBtn onAfterCreated={setCardToken} />}

              <div className="[&>button]:sm:mx-14 [&>button]:mt-10 sm:flex sm:justify-center sm:flex-row-reverse sm:mt-6">
                <Button
                  onClick={clickApply}
                  disabled={
                    !isAgree ||
                    startDatedAt === "" ||
                    (!userInfo.isAuthedCard && !cardToken) ||
                    (!userInfo.isUploadedIdDoc && idImgs.length === 0)
                  }
                >
                  上記の内容で申し込む
                </Button>
                <Button onClick={clickBack} subMode>
                  戻る
                </Button>
              </div>

              <ImgsModal isOpen={isOpenImgsModal} close={closeImgsModal} srcs={idImgSrcs} />
            </div>
          )}
        </>
      )}
    </Layout>
  );
};

export default Application;
