import { FC, memo } from "react";
import { Carousel } from "react-responsive-carousel";

import { DEFAULT_MANSION_ICON } from "common/constants";

import "styles/Carousel.css";
import "react-responsive-carousel/lib/styles/carousel.css";

type Props = {
  imgs: string[];
};

/**
 * マンション写真
 * @param Props
 * @returns
 */
const MansionImg: FC<Props> = ({ imgs }) => {
  return (
    <>
      {imgs.length > 0 ? (
        <div className="nandoya-carousel">
          <Carousel statusFormatter={(_) => ""}>
            {imgs.map((img: string, index: number) => {
              return (
                <div key={index}>
                  <img className="carousel-img" src={img} alt="img" />
                </div>
              );
            })}
          </Carousel>
        </div>
      ) : (
        <div className="mt-8 mx-auto w-4/5 h-40 border-2 border-black flex justify-center align-middle sm:w-2/5">
          <img className="w-12" src={DEFAULT_MANSION_ICON} alt="mansion-icon" />
        </div>
      )}
    </>
  );
};

export default memo(MansionImg);
