import { FC, memo, useMemo } from "react";

import { CONTRACT_STATUS } from "common/constants";
import { TrunkData } from "ducks/trunk/type";

import "styles/Table.css";

type Props = {
  trunks: TrunkData[];
  clickApply: Function;
};

/**
 * トランク一覧テーブル
 * @param Props
 * @returns
 */
const TrunkTable: FC<Props> = ({ trunks, clickApply }) => {
  const titles: string[] = useMemo(() => ["トランクルーム番号", "空き状況", "申込み"], []);

  return (
    <div className="nandoya-table">
      <table>
        <thead>
          <tr>
            {titles.map((title: string, index: number) => {
              return (
                <th key={index} scope="col">
                  {title}
                </th>
              );
            })}
          </tr>
        </thead>

        <tbody>
          {trunks.map((trunk: TrunkData, index: number) => (
            <tr key={index}>
              <td>{trunk.trunkNumber}</td>
              {trunk.trunkCurrentStatus === CONTRACT_STATUS.NO_CONTRACT ? (
                <>
                  <td>空室</td>
                  <td>
                    <span className="link-a-tag" onClick={() => clickApply(trunk)}>
                      申込み
                    </span>
                  </td>
                </>
              ) : (
                <>
                  <td>満室</td>
                  <td className="!pl-8 sm:text-left sm:!pl-10">-</td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default memo(TrunkTable);
