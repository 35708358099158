import { FC, ChangeEvent } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

import "styles/Input.css";

type Props = {
  label: string;
  type: string;
  id: string;
  value?: string;
  defaultValue?: string;
  placeHolder?: string;
  errMsg?: string;
  maxLength?: number;
  disabled?: boolean;
  isError?: boolean;
  max?: string;
  min?: string;
  onChange?: Function;
  smallText?: string;
};

/**
 * 共通Input
 * @param Props
 * @returns
 */
const Input: FC<Props> = ({
  label,
  type,
  id,
  value = undefined,
  defaultValue = undefined,
  placeHolder = "",
  errMsg = "",
  max = undefined,
  min = undefined,
  maxLength = undefined,
  disabled = false,
  isError = false,
  onChange = () => {},
  smallText = "",
}) => {
  return (
    <div className="nandoya-input">
      <div>
        <label htmlFor={id}>{label}</label>
        {smallText && <small>{smallText}</small>}
        <div className="input-div">
          <input
            type={type}
            name={id}
            id={id}
            value={value}
            defaultValue={defaultValue}
            className={isError ? "err-input" : ""}
            maxLength={maxLength}
            disabled={disabled}
            placeholder={placeHolder}
            max={max}
            min={min}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
          />
          {isError && (
            <div className="err-ex">
              <ExclamationCircleIcon className="err-ex-icon" />
            </div>
          )}
        </div>
        {isError && <p className="err-msg">{errMsg ? errMsg : "必須入力項目です"}</p>}
      </div>
    </div>
  );
};

export default Input;
