import { FC, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "app/hooks";
import Layout from "components/Layout";
import TwoColContents from "components/common/TwoColContents";
import Title from "components/common/Title";
import ContentCard from "components/common/ContentCard";
import Loader from "components/common/Loader";

import {
  fetchAsyncGetCompanyProf,
  selectCompanyProfile,
  selectIsLoading,
} from "ducks/company/slice";
import { CompanyProfile } from "ducks/company/type";

type Props = {
  title: string;
};

/**
 * 会社概要画面
 * @param Props
 * @returns
 */
const Profile: FC<Props> = ({ title }) => {
  const dispatch = useAppDispatch();
  const isLoading: boolean = useAppSelector(selectIsLoading);
  const companyProfile: CompanyProfile = useAppSelector(selectCompanyProfile);

  useEffect(() => {
    dispatch(fetchAsyncGetCompanyProf());
  }, [dispatch]);

  return (
    <Layout title={title}>
      {companyProfile.contents.length === 0 || isLoading ? (
        <Loader isActive={isLoading}></Loader>
      ) : (
        <div className="pb-16">
          <Title>会社概要</Title>

          <ContentCard>
            <TwoColContents rows={companyProfile.contents} />
          </ContentCard>
        </div>
      )}
    </Layout>
  );
};

export default Profile;
