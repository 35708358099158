import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "app/hooks";
import { PAGES } from "common/PAGES";
import {
  fetchAsyncPutLeaveApplication,
  selectIsLoading,
  selectIsRendering,
  selectTargetTrunk,
  unsetIsRendering,
} from "ducks/trunk/slice";
import { TrunkData } from "ducks/trunk/type";
import { UserInfo } from "ducks/auth/type";
import { selectUserInfo } from "ducks/auth/slice";

/**
 * 退去申請画面用フックス
 * @returns
 */
export const useLeageApplication = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { trunkNumber } = useParams();

  const userInfo: UserInfo = useAppSelector(selectUserInfo);
  const targetTrunk: TrunkData | null = useAppSelector(selectTargetTrunk);
  const isLoading: boolean = useAppSelector(selectIsLoading);
  const isRendering: boolean = useAppSelector(selectIsRendering);

  const [preferredDate, setPreferredDate] = useState<string>("");

  /** 退去申込み確定 */
  const clickApply = useCallback(() => {
    if (targetTrunk && preferredDate) {
      dispatch(
        fetchAsyncPutLeaveApplication({
          trunkBody: {
            trunkNumber: targetTrunk.trunkNumber,
            contractEndedAt: preferredDate,
          },
          emailBody: {
            userName: userInfo.userName,
            userNameKana: userInfo.userNameKana,
            email: userInfo.email,
            apartmentName: targetTrunk.apartmentName,
            trunkNumber: targetTrunk.trunkNumber,
            contractEndedAt: preferredDate,
          },
        })
      );
    }
  }, [dispatch, preferredDate, targetTrunk, userInfo]);

  /** 戻る */
  const clickBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  /** 申請完了後遷移 */
  useEffect(() => {
    if (!targetTrunk || isRendering) {
      dispatch(unsetIsRendering());
      navigate(PAGES.LEAVE_APPLICATION_END.PATH);
    }
  }, [navigate, dispatch, isRendering, targetTrunk]);

  /** ブラウザバッグ等拒否 */
  useEffect(() => {
    (!targetTrunk || trunkNumber !== targetTrunk.trunkNumber) && navigate(PAGES.MYPAGE_TOP.PATH);
  }, [navigate, targetTrunk, trunkNumber]);

  return {
    trunkNumber,
    preferredDate,
    isLoading,
    setPreferredDate,
    clickApply,
    clickBack,
  };
};
