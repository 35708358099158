import { ChangeEvent, FC, memo, ReactNode, useMemo } from "react";

import { Trunk, Trunks } from "ducks/trunkItem/type";

type Props = {
  trunks: Trunks;
  dispTrunkNum: string;
  setDispTrunkNum: Function;
};

/**
 * 表示するトランクルームのセレクト
 * @returns
 */
const DispTrunkSelect: FC<Props> = ({ trunks, dispTrunkNum, setDispTrunkNum }) => {
  /** トランク番号Option */
  const options: ReactNode[] = useMemo(() => {
    const options: ReactNode[] = [
      <option key={999} value="">
        全て表示
      </option>,
    ];
    trunks.forEach((trunk: Trunk, index: number) => {
      options.push(
        <option key={index} value={trunk.trunkNumber}>
          {trunk.trunkNumber}
        </option>
      );
    });
    return options;
  }, [trunks]);

  /** 表示するトランク番号のセレクト */
  const changeDispTrunkNum = (e: ChangeEvent<HTMLSelectElement>) => {
    const value: string = e.target.value;
    setDispTrunkNum(value);
  };

  return (
    <div className="[&_label]:text-sm [&_label]:font-medium [&_label]:text-black [&_label]:sm:text-base [&_select]:w-full [&_select]:rounded-lg">
      <div>
        <label>表示するトランクルーム</label>
        <select value={dispTrunkNum} onChange={changeDispTrunkNum}>
          {options}
        </select>
      </div>
    </div>
  );
};

export default memo(DispTrunkSelect);
