import { FC, memo } from "react";
import { Carousel } from "react-responsive-carousel";

import Button from "components/common/Button";
import Modal from "components/common/Modal";

import "styles/CarouselModal.css";
import "react-responsive-carousel/lib/styles/carousel.css";

type Props = {
  isOpen: boolean;
  close: VoidFunction;
  srcs: string[];
};

/**
 * 写真確認用のモーダル
 * @returns
 */
const ImgsModal: FC<Props> = ({ isOpen, close, srcs }) => {
  return (
    <Modal isOpen={isOpen}>
      <div className="my-4">
        <div className="nandoya-carousel-modal">
          <Carousel statusFormatter={(_) => ""}>
            {srcs.map((img: string, index: number) => {
              return (
                <div key={index}>
                  <img className="carousel-img" src={img} alt="img" />
                </div>
              );
            })}
          </Carousel>
        </div>

        <Button onClick={close} subMode>
          閉じる
        </Button>
      </div>
    </Modal>
  );
};

export default memo(ImgsModal);
