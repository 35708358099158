import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { API } from "aws-amplify";
import { RootState } from "app/store";
import { GetApartmentsRes, GetUsersRes } from "common/types";
import { IN_AGW_NAME, AGW_PATHS, EX_AGW_NAME } from "common/constants";
import { initialState } from "ducks/auth/initialState";
import { AuthState } from "ducks/auth/type";

/**
 * ログインユーザ情報の取得
 * @param email ユーザのメルアド
 **/
export const fetchAsyncGetUsers = createAsyncThunk(
  "auth/getUsers",
  async (email: string, thunkAPI) => {
    const params = {
      queryStringParameters: {
        email,
      },
    };
    try {
      return await API.get(IN_AGW_NAME, AGW_PATHS.USERS, params);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**
 * マンション名取得
 * @param aptNum マンション番号
 */
export const fetchAsyncGetApartmentName = createAsyncThunk(
  "auth/getApartmentName",
  async (apartmentNumber: string, thunkAPI) => {
    const params = {
      queryStringParameters: {
        apartmentNumber,
      },
    };

    try {
      const res = await API.get(EX_AGW_NAME, AGW_PATHS.APARTMENTS, params);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**
 * クレジットカード登録
 * @param email Email
 * @param token payjpトークン
 */

export const fetchAsyncPostCards = createAsyncThunk(
  "auth/postPayToken",
  async (args: { email: string; token: string }, thunkAPI) => {
    const params = {
      body: {
        email: args.email,
        token: args.token,
      },
    };

    try {
      return await API.post(IN_AGW_NAME, AGW_PATHS.CARDS, params);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/** Authスライス */
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIdImgs(state: AuthState, action: PayloadAction<{ files: File[] }>) {
      return {
        ...state,
        idImgs: action.payload.files,
      };
    },
    setIdImgSrcs(state: AuthState, action: PayloadAction<string[]>) {
      return {
        ...state,
        idImgSrcs: action.payload,
      };
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<AuthState>) => {
    builder
      .addCase(
        fetchAsyncGetUsers.fulfilled,
        (state: AuthState, action: PayloadAction<GetUsersRes>) => {
          if (action.payload.data.length > 0) {
            return {
              ...state,
              userInfo: action.payload.data[0],
              isLoading: false,
            };
          }
        }
      )
      .addCase(fetchAsyncGetUsers.pending, (state: AuthState) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(fetchAsyncGetUsers.rejected, () => {
        window.location.href = "/error?e_code=auth_01";
      })

      .addCase(
        fetchAsyncGetApartmentName.fulfilled,
        (state: AuthState, action: PayloadAction<GetApartmentsRes>) => {
          if (action.payload.data.length) {
            return {
              ...state,
              aptName: action.payload.data[0].apartmentName,
            };
          }
        }
      )
      .addCase(fetchAsyncGetApartmentName.rejected, () => {
        window.location.href = "/error?e_code=auth_02";
      })

      .addCase(fetchAsyncPostCards.fulfilled, (state: AuthState) => {
        return {
          ...state,
          userInfo: {
            ...state.userInfo,
            isAuthedCard: true,
          },
        };
      })
      .addCase(fetchAsyncPostCards.rejected, () => {
        window.location.href = "/error?e_code=auth_03";
      });
  },
});

export const { setIdImgs, setIdImgSrcs } = authSlice.actions;

export const selectIsLoading = (state: RootState) => state.auth.isLoading;
export const selectUserInfo = (state: RootState) => state.auth.userInfo;
export const selectIdImgs = (state: RootState) => state.auth.idImgs;
export const selectIdImgSrcs = (state: RootState) => state.auth.idImgSrcs;
export const selectAptName = (state: RootState) => state.auth.aptName;

export default authSlice.reducer;
