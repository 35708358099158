import { useCallback, useMemo } from "react";
import { I18n } from "aws-amplify";
import { translations } from "@aws-amplify/ui";

/**
 * ログイン画面用フックス
 * @returns
 */
export const useLogin = () => {
  const formFields = useMemo(() => {
    return {
      signUp: {
        email: {
          isRequired: true,
          labelHidden: false,
          placeholder: "例）info@example.com",
          order: 6,
          label: "メールアドレス",
        },
        password: {
          isRequired: true,
          labelHidden: false,
          placeholder: "",
          order: 7,
          label: "パスワード \n※6文字以上",
        },
        confirm_password: {
          isRequired: true,
          labelHidden: false,
          placeholder: "",
          order: 8,
          label: "パスワードの確認",
        },
      },
    };
  }, []);

  /** UI日本語化設定 */
  const setUiJp = useCallback(() => {
    I18n.putVocabularies(translations);
    I18n.setLanguage("ja");
    I18n.putVocabulariesForLanguage("ja", {
      "Invalid phone number format.": "電話番号を正しく入力してください（ハイフンなし）",
      "Invalid email address format.": "メールアドレスを正しく入力してください",
      "Your passwords must match": "パスワードが一致しません",
      "Password did not conform with policy: Password not long enough":
        "パスワードは６文字以上を入力してください",
      "Password did not conform with policy: Password must have lowercase characters":
        "パスワードに小文字の英字を含めてください",
      "Password did not conform with policy: Password must have uppercase characters":
        "パスワードに大文字の英字を含めてください",
      "Password did not conform with policy: Password must have symbol characters":
        "パスワードに記号を含めてください",
      "Incorrect username or password.": "メールアドレスもしくはパスワードが違います",
      "User does not exist.": "存在しないユーザです",
      "We Sent A Code": "コードを送信しました",
      "Your code is on the way. To log in, enter the code we sent you. It may take a minute to arrive.":
        "ログインするには、メールに記載されたコードを入力してください。コードを受信するまで数分かかる場合があります。",
      "Invalid verification code provided, please try again.":
        "無効な認証コードが入力されました。再度お試しください。",
    });
  }, []);

  return { formFields, setUiJp };
};
