import { FC, memo } from "react";
import LoadingOverlay from "react-loading-overlay-ts";

type Props = {
  isActive: boolean;
};

/**
 * オーバーレイローディング
 * @param Props
 * @returns
 */
const Loader: FC<Props> = ({ isActive }) => {
  return (
    <LoadingOverlay
      className="!fixed [inset:0] m-auto z-[1001]"
      active={isActive}
      spinner
      text="Loading..."
    ></LoadingOverlay>
  );
};

export default memo(Loader);
