import { useCallback, useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "app/hooks";

import { selectUserInfo } from "ducks/auth/slice";
import { UserInfo } from "ducks/auth/type";
import {
  fetchAsyncGetTrunkItems,
  selectIsLoading,
  selectTrunkItems,
  selectTrunks,
  setIsLoading,
} from "ducks/trunkItem/slice";
import { Trunk, TrunkItem, TrunkItems } from "ducks/trunkItem/type";

/**　収納物一覧画面用 */
export const useTrunkItems = () => {
  const dispatch = useAppDispatch();

  const userInfo: UserInfo = useAppSelector(selectUserInfo);
  const isLoading: boolean = useAppSelector(selectIsLoading);
  const trunks: Trunk[] = useAppSelector(selectTrunks);
  const trunkItems: TrunkItems = useAppSelector(selectTrunkItems);

  const [dispTrunkNum, setDispTrunkNum] = useState<string>("");
  const [dispTrunkItems, setDispTrunkItems] = useState<TrunkItems>([]);

  /** 収納物情報を取得 */
  const getTrunkItems = useCallback(async () => {
    if (userInfo.email) {
      await dispatch(fetchAsyncGetTrunkItems(userInfo.email));
    }
  }, [dispatch, userInfo]);

  /** 表示する収納物の設定 */
  useEffect(() => {
    if (dispTrunkNum) {
      setDispTrunkItems(trunkItems.filter((item: TrunkItem) => item.trunkNumber === dispTrunkNum));
    } else {
      setDispTrunkItems(trunkItems);
    }
  }, [dispTrunkNum, trunkItems]);

  /** 初回画面遷移時の取得 */
  useEffect(() => {
    dispatch(setIsLoading(true));
    getTrunkItems();
  }, [dispatch, getTrunkItems]);

  return {
    dispatch,
    isLoading,
    trunks,
    trunkItems,
    dispTrunkNum,
    dispTrunkItems,
    setDispTrunkNum,
    getTrunkItems,
  };
};
