import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "app/hooks";
import { PAGES } from "common/PAGES";
import { MAX_LEAVE_IMG_MAX } from "common/constants";
import { readAsDataURL } from "common/common";

import { fetchAsyncPutLeavePictures, selectTargetTrunk, unsetTargetTrunk } from "ducks/trunk/slice";
import { TrunkData } from "ducks/trunk/type";
import { UserInfo } from "ducks/auth/type";
import { selectUserInfo } from "ducks/auth/slice";

/** 退去写真登録用のフックス */
export const useLeaveImgsUpload = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userInfo: UserInfo = useAppSelector(selectUserInfo);
  const targetTrunk: TrunkData | null = useAppSelector(selectTargetTrunk);

  // ファイル自体の保存
  const [imgFiles, setImgFiles] = useState<File[]>([]);
  // 画面で表示する用の保存
  const [imgSrcs, setImgSrcs] = useState<string[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [isOpenImgsModal, setIsOpenImgsModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /** 写真確認モーダルクローズ */
  const closeImgsModal = useCallback(() => {
    setIsOpenImgsModal(false);
  }, []);

  /** 写真input */
  const changeImgs = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setIsError(false);
      const fileList: FileList = e.target.files;
      const files: File[] = Array.from(fileList);
      if (files.length > MAX_LEAVE_IMG_MAX) {
        setIsError(true);
      } else {
        setImgFiles(files);

        const tmp: string[] = [];
        for (const file of files) {
          const res = await readAsDataURL(file);
          tmp.push(res);
        }
        setImgSrcs(tmp);
      }
    }
  }, []);

  /** 登録ボタンを押下 */
  const clickUpload = useCallback(async () => {
    setIsLoading(true);
    if (targetTrunk) {
      await dispatch(
        fetchAsyncPutLeavePictures({
          trunkNumber: targetTrunk.trunkNumber,
          email: userInfo.email,
          imgs: imgFiles,
        })
      );
      navigate(PAGES.LEAVE_IMGS_UPLOAD_END.PATH, { state: targetTrunk.trunkNumber });
    } else {
      navigate(PAGES.MYPAGE_TOP.PATH);
    }
    setIsLoading(false);
  }, [dispatch, navigate, imgFiles, targetTrunk, userInfo]);

  /** 戻るボタンを押下 */
  const clickBack = useCallback(() => {
    dispatch(unsetTargetTrunk());
    navigate(PAGES.MYPAGE_TOP.PATH);
  }, [dispatch, navigate]);

  useEffect(() => {
    if (!targetTrunk) {
      navigate(PAGES.MYPAGE_TOP.PATH);
    }
  }, [navigate, targetTrunk]);

  return {
    targetTrunk,
    imgFiles,
    imgSrcs,
    isError,
    isOpenImgsModal,
    isLoading,
    setIsOpenImgsModal,
    closeImgsModal,
    changeImgs,
    clickUpload,
    clickBack,
  };
};
