import { ReactNode } from "react";
import Error from "containers/Error";
import Detail from "containers/trunk/Detail";
import Application from "containers/trunk/Application";
import ApplicationEnd from "containers/trunk/ApplicationEnd";
import ApplicationError from "containers/trunk/ApplicationError";
import Top from "containers/mypage/Top";
import ContractorDetail from "containers/mypage/ContractorDetail";
import LeaveApplication from "containers/mypage/LeaveApplication";
import LeaveApplicationEnd from "containers/mypage/LeaveApplicationEnd";
import LeaveImgsUpload from "containers/mypage/LeaveImgsUpload";
import LeaveImgsUploadEnd from "containers/mypage/LeaveImgsUploadEnd";
import Profile from "containers/company/Profile";
import Law from "containers/company/Law";
import TrunkItems from "containers/mypage/TrunkItems";

export type PageType = {
  NAME: string;
  PATH: string;
  CONTAINER: ReactNode;
};

type PagesType = {
  ERROR: PageType;
  TRUNK_DETAIL: PageType;
  TRUNK_APPLICATION: PageType;
  TRUNK_APPLICATION_END: PageType;
  TRUNK_APPLICATION_ERROR: PageType;
  MYPAGE_TOP: PageType;
  CONTRACTOR_DETAIL: PageType;
  LEAVE_APPLICATION: PageType;
  LEAVE_APPLICATION_END: PageType;
  LEAVE_IMGS_UPLOAD: PageType;
  LEAVE_IMGS_UPLOAD_END: PageType;
  COMPANY_PROFILE: PageType;
  COMPANY_LAW: PageType;
  TRUNK_ITEMS: PageType;
};

/**
 * ページ設定
 */
export const PAGES: PagesType = {
  ERROR: {
    NAME: "エラー",
    PATH: "/*",
    CONTAINER: <Error />,
  },
  TRUNK_DETAIL: {
    NAME: "トランクルーム詳細",
    PATH: "/trunk",
    CONTAINER: <Detail title="トランクルーム詳細" />,
  },
  TRUNK_APPLICATION: {
    NAME: "トランクルーム利用申込確認",
    PATH: "/trunk/application",
    CONTAINER: <Application title="トランクルーム利用申込確認" />,
  },
  TRUNK_APPLICATION_END: {
    NAME: "トランクルーム利用申込完了",
    PATH: "/trunk/application/end",
    CONTAINER: <ApplicationEnd title="トランクルーム利用申込完了" />,
  },
  TRUNK_APPLICATION_ERROR: {
    NAME: "トランクルーム利用申込エラー",
    PATH: "/trunk/application/error",
    CONTAINER: <ApplicationError title="トランクルーム利用申込エラー" />,
  },
  MYPAGE_TOP: {
    NAME: "マイページ",
    PATH: "/",
    CONTAINER: <Top title="マイページ" />,
  },
  CONTRACTOR_DETAIL: {
    NAME: "契約者情報",
    PATH: "/contractorDetail",
    CONTAINER: <ContractorDetail title="契約者情報" />,
  },
  LEAVE_APPLICATION: {
    NAME: "退去申請",
    PATH: "/leaveApplication/:trunkNumber",
    CONTAINER: <LeaveApplication title="退去申請" />,
  },
  LEAVE_APPLICATION_END: {
    NAME: "退去申請完了",
    PATH: "/leaveApplication/end",
    CONTAINER: <LeaveApplicationEnd title="退去申請完了" />,
  },
  LEAVE_IMGS_UPLOAD: {
    NAME: "退去写真の登録",
    PATH: "/leaveImgsUpload",
    CONTAINER: <LeaveImgsUpload title="退去写真の登録" />,
  },
  LEAVE_IMGS_UPLOAD_END: {
    NAME: "退去写真の登録完了",
    PATH: "/leaveImgsUpload/end",
    CONTAINER: <LeaveImgsUploadEnd title="退去写真の登録完了" />,
  },
  COMPANY_PROFILE: {
    NAME: "会社概要",
    PATH: "/company/profile",
    CONTAINER: <Profile title="会社概要" />,
  },
  COMPANY_LAW: {
    NAME: "特定商取引法に基づく表記",
    PATH: "/company/law",
    CONTAINER: <Law title="特定商取引法に基づく表記" />,
  },
  TRUNK_ITEMS: {
    NAME: "収納物一覧",
    PATH: "/trunkItems",
    CONTAINER: <TrunkItems title="収納物一覧" />,
  },
};
