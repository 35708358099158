import { FC, memo } from "react";
import { IoChevronBackSharp, IoChevronForwardSharp } from "react-icons/io5";

type Props = {
  pageNum: number;
  isDisabledNext: boolean;
  isDisabledPrev: boolean;
  clickNext: VoidFunction;
  clickPrev: VoidFunction;
};

/**
 * ページネーション
 * @param Props
 * @returns
 */
const Pagenation: FC<Props> = ({
  pageNum,
  isDisabledNext,
  isDisabledPrev,
  clickNext,
  clickPrev,
}) => {
  return (
    <div className="mt-4 flex justify-end">
      <div className="flex">
        <span
          className={isDisabledPrev ? "text-gray-300 cursor-default" : " cursor-pointer"}
          onClick={isDisabledPrev ? () => {} : clickPrev}
        >
          <IoChevronBackSharp size={"2em"} />
        </span>
        <div className="mt-1">{!(isDisabledNext && isDisabledPrev) && <p>{pageNum}</p>}</div>
        <span
          className={isDisabledNext ? "text-gray-300 cursor-default" : "cursor-pointer"}
          onClick={isDisabledNext ? () => {} : clickNext}
        >
          <IoChevronForwardSharp size={"2em"} />
        </span>
      </div>
    </div>
  );
};

export default memo(Pagenation);
