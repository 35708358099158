import { FC, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { SelectField, TextField } from "@aws-amplify/ui-react";
import { Authenticator } from "@aws-amplify/ui-react";

import { useAppDispatch, useAppSelector } from "app/hooks";
import { getEndDate } from "common/common";
import { HEADER_LOGO, Q_PARAMS } from "common/constants";
import { useLogin } from "hooks/loginHooks";
import { fetchAsyncGetApartmentName, selectAptName } from "ducks/auth/slice";

import "@aws-amplify/ui-react/styles.css";
import "styles/Login.css";

/**
 * ログイン
 * @returns
 */
const Login: FC = () => {
  const dispatch = useAppDispatch();
  const apartmentName: string = useAppSelector(selectAptName);
  const { formFields, setUiJp } = useLogin();

  const year = useMemo(() => new Date().getFullYear(), []);
  const [paramAPTNumber, setParamAPTNumber] = useState<string>("");

  useEffect(() => {
    setUiJp();
  }, [setUiJp]);

  /** 全マンション情報の取得 & パラメータからマンションIDを取得 */
  useLayoutEffect(() => {
    const apartmentNumber: string | null = new URLSearchParams(window.location.search).get(
      Q_PARAMS.APARTMENT_NUMBER
    );
    if (apartmentNumber) {
      dispatch(fetchAsyncGetApartmentName(apartmentNumber));
      setParamAPTNumber(apartmentNumber);
    }
  }, [dispatch]);

  return (
    <>
      <div className="bg-main flex justify-center mb-20">
        <div className="w-40">
          <img src={HEADER_LOGO} alt="logo" />
        </div>
      </div>
      <div className="nandoya-login">
        <Authenticator
          hideSignUp={apartmentName ? false : true}
          loginMechanisms={["email"]}
          formFields={formFields}
          components={{
            Footer() {
              return (
                <div className="flex justify-center mt-4">
                  <small className="block mt-2 sm:mt-10 sm:text-[16px]">
                    All rights reserved. Kamiya associate inc. &copy; {year}
                  </small>
                </div>
              );
            },
            SignUp: {
              FormFields() {
                const [selectedYear, setSelectedYear] = useState<string>("");
                const [selectedMonth, setSelectedMonth] = useState<string>("");
                const [selectedDate, setSelectedDate] = useState<string>("");

                /** 年Option */
                const yearOptions = useMemo(() => {
                  const options = [];
                  const date: Date = new Date();
                  for (let year = date.getFullYear(); year >= 1900; year--) {
                    options.push(
                      <option key={year} value={year}>
                        {year}
                      </option>
                    );
                  }
                  return options;
                }, []);

                /** 月Option */
                const monthOptions = useMemo(() => {
                  const options = [];
                  for (let month = 1; month <= 12; month++) {
                    options.push(
                      <option key={month} value={month}>
                        {month}
                      </option>
                    );
                  }
                  return options;
                }, []);

                /** 日Option */
                const dateOptions = useMemo(() => {
                  const options = [];
                  const endDate = getEndDate(Number(selectedYear), Number(selectedMonth));

                  // 設定されている日付が存在しない場合は新しい年月の月末日に設定
                  Number(selectedDate) > endDate && setSelectedDate(String(endDate));

                  for (let date = 1; date <= endDate; date++) {
                    options.push(
                      <option key={date} value={date}>
                        {date}
                      </option>
                    );
                  }
                  return options;
                }, [selectedDate, selectedMonth, selectedYear]);

                return (
                  <>
                    <SelectField
                      name="custom:apartmentNumber"
                      label="マンション"
                      defaultValue={paramAPTNumber}
                      required
                    >
                      <option value={paramAPTNumber}>{apartmentName}</option>
                    </SelectField>

                    <div className="grid grid-cols-2 gap-x-4">
                      <TextField
                        type="text"
                        name="family_name"
                        placeholder="例）山田"
                        label="姓"
                        required
                      />
                      <TextField
                        type="text"
                        name="given_name"
                        placeholder="例）太郎"
                        label="名"
                        required
                      />
                    </div>

                    <div className="grid grid-cols-2 gap-x-4">
                      <TextField
                        type="text"
                        name="custom:lastNameKana"
                        placeholder="例）ヤマダ"
                        label="姓（カナ）"
                        required
                      />
                      <TextField
                        type="text"
                        name="custom:firstNameKana"
                        placeholder="例）タロウ"
                        label="名（カナ）"
                        required
                      />
                    </div>

                    <label className="text-[#304050] mb-[-15px]">生年月日</label>
                    <div className="flex">
                      <div className="w-2/3">
                        <SelectField
                          name="custom:birthdateYear"
                          label={undefined}
                          required
                          placeholder="年を選択"
                          value={selectedYear}
                          onChange={(e) => setSelectedYear(e.target.value)}
                        >
                          {yearOptions}
                        </SelectField>
                      </div>
                      <div className="flex items-center ml-1">年</div>
                    </div>
                    <div className="flex">
                      <div>
                        <SelectField
                          name="custom:birthdateMonth"
                          label={undefined}
                          required
                          placeholder="月を選択"
                          value={selectedMonth}
                          onChange={(e) => setSelectedMonth(e.target.value)}
                        >
                          {monthOptions}
                        </SelectField>
                      </div>
                      <div className="flex items-center mx-1">月</div>
                      <div>
                        <SelectField
                          name="custom:birthdateDate"
                          label={undefined}
                          required
                          placeholder="日を選択"
                          value={selectedDate}
                          onChange={(e) => setSelectedDate(e.target.value)}
                        >
                          {dateOptions}
                        </SelectField>
                      </div>
                      <div className="flex items-center ml-1">日</div>
                    </div>

                    <TextField
                      type="text"
                      name="address"
                      placeholder="例）201"
                      label="部屋番号"
                      required
                    />

                    <TextField
                      type="text"
                      name="phone_number"
                      placeholder="例）08012345678"
                      label="電話番号"
                      maxLength={11}
                      required
                    />

                    <Authenticator.SignUp.FormFields />
                  </>
                );
              },
            },
          }}
        ></Authenticator>
      </div>
    </>
  );
};

export default Login;
