import { FC, memo, ReactNode } from "react";

type Props = {
  children: ReactNode;
};

/**
 * タイトル
 * @param Props
 * @returns
 */
const Title: FC<Props> = ({ children }) => {
  return <h1 className="pt-10 text-center text-xl font-bold sm:text-2xl">{children}</h1>;
};

export default memo(Title);
