import { TrunkState } from "ducks/trunk/type";
export const initialState: TrunkState = {
  isLoading: false,
  isRendering: false,
  apartment: {
    apartmentNumber: "",
    apartmentName: "",
    imageUrls: [],
    size: 0,
    handlingFee: 0,
    price: 0,
    width: 0,
    depth: 0,
    height: 0,
    maxWeight: 0,
    deposit: 0,
    address: "",
    phoneNumber: "",
  },
  trunk: {
    data: [],
    lastEvaluatedKey: {
      sortKey: "",
      createdAt: 0,
      partitionKey: "",
    },
  },
  targetTrunk: null,
  contractedTrunk: {
    data: [],
    lastEvaluatedKey: {
      sortKey: "",
      createdAt: 0,
      partitionKey: "",
    },
  },
  nextToken: "",
};
