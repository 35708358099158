import { FC, ReactNode } from "react";

import { BTN_LOADING } from "common/constants";

import "styles/Button.css";

type Props = {
  children: ReactNode;
  onClick: VoidFunction;
  disabled?: boolean;
  subMode?: boolean;
  isLoading?: boolean;
};

/**
 * 共通ボタン
 * @param Props
 * @returns
 */
const Button: FC<Props> = ({
  children,
  onClick,
  disabled = false,
  subMode = false,
  isLoading = false,
}) => {
  return (
    <button
      className={subMode ? "nandoya-btn sub-mode" : "nandoya-btn"}
      onClick={onClick}
      disabled={disabled || isLoading}
    >
      {isLoading ? <img src={BTN_LOADING} alt="load" /> : children}
    </button>
  );
};

export default Button;
