import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { API } from "aws-amplify";
import axios from "axios";

import { RootState } from "app/store";
import { compressImg, createUuid } from "common/common";
import {
  EX_AGW_NAME,
  AGW_PATHS,
  IN_AGW_NAME,
  BUCKET_DIR_NAMES,
  CONTRACT_STATUS,
} from "common/constants";
import {
  GetPutSignedUrlsRes,
  GetTrunkItemsRes,
  GetTrunksRes,
  PostTrunkItemsReq,
} from "common/types";
import { initialState } from "ducks/trunkItem/initialState";
import { Trunk, TrunkItems, TrunkItemState, Trunks } from "ducks/trunkItem/type";

/**
 * 収納物を全取得
 * @param contractorEmail メルアド
 **/
export const fetchAsyncGetTrunkItems = createAsyncThunk(
  "trunkItem/getTrunkItems",
  async (contractorEmail: string, thunkAPI) => {
    try {
      // ユーザのトランク情報を取得
      let trunks: Trunks = [];
      while (true) {
        const res: GetTrunksRes = await API.get(EX_AGW_NAME, AGW_PATHS.TRUNKS, {
          queryStringParameters: { contractorEmail },
        });

        trunks = [...trunks, ...res.data];
        if (!res.lastEvaluatedKey.sortKey) {
          break;
        }
      }

      // 審査中は除外
      trunks = trunks.filter((t: Trunk) => t.trunkCurrentStatus !== CONTRACT_STATUS.REVIEW);

      // トランク情報に紐づく収納物を全取得
      let trunkItems: TrunkItems = [];
      for (const trunk of trunks) {
        const res: GetTrunkItemsRes = await API.get(IN_AGW_NAME, AGW_PATHS.TRUNKS_ITEMS, {
          queryStringParameters: { trunkNumber: trunk.trunkNumber },
        });

        trunkItems = [...trunkItems, ...res.data];
      }

      return { trunks, trunkItems };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**
 * 収納物を登録
 * @param args リクエスト情報
 **/
export const fetchAsyncPostTrunkItem = createAsyncThunk(
  "trunkItem/postTrunkItem",
  async (args: { body: PostTrunkItemsReq; img: File }, thunkAPI) => {
    try {
      const uuid: string = createUuid();
      const fileType: string | undefined = args.img.name.split(".").pop();
      args.body.image = `${BUCKET_DIR_NAMES.TRUNK_ITEM}/${uuid}.${fileType}`;

      // DB登録
      await API.post(IN_AGW_NAME, `${AGW_PATHS.TRUNKS_ITEMS}/${uuid}`, { body: args.body });

      // S3登録
      const res: GetPutSignedUrlsRes = await API.get(IN_AGW_NAME, AGW_PATHS.PUT_SIGNED_URLS, {
        queryStringParameters: {
          fileNames: args.body.image,
        },
      });

      if (res.data.length > 0 && res.data[0].putSignedUrls[0].length > 0) {
        const options = {
          headers: {
            "Content-Type": args.img.type,
          },
        };
        // ファイルを圧縮
        const compressedImg: File = await compressImg(args.img);
        await axios.put(res.data[0].putSignedUrls[0], compressedImg, options);
      } else {
        throw Error("[GET]putSignedUrls Response Error");
      }

      return;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**
 * 収納物を削除
 * @param id
 */
export const fetchAsyncDeleteTrunkItem = createAsyncThunk(
  "trunkItem/deleteTrunkItem",
  async (id: string, thunkAPI) => {
    try {
      await API.del(IN_AGW_NAME, `${AGW_PATHS.TRUNKS_ITEMS}/${id}`, {});
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/** 収納物スライス */
export const trunkItemSlice = createSlice({
  name: "trunkItem",
  initialState,
  reducers: {
    setIsLoading(state: TrunkItemState, action: PayloadAction<boolean>) {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<TrunkItemState>) => {
    builder
      .addCase(
        fetchAsyncGetTrunkItems.fulfilled,
        (
          state: TrunkItemState,
          action: PayloadAction<{ trunks: Trunks; trunkItems: TrunkItems }>
        ) => {
          return {
            ...state,
            trunks: action.payload.trunks,
            trunkItems: action.payload.trunkItems,
            isLoading: false,
          };
        }
      )
      .addCase(fetchAsyncGetTrunkItems.rejected, () => {
        window.location.href = "/error?e_code=trunkItem_01";
      })

      .addCase(fetchAsyncPostTrunkItem.fulfilled, () => {})
      .addCase(fetchAsyncPostTrunkItem.rejected, () => {
        window.location.href = "/error?e_code=trunkItem_02";
      })

      .addCase(fetchAsyncDeleteTrunkItem.fulfilled, () => {})

      .addCase(fetchAsyncDeleteTrunkItem.rejected, () => {
        window.location.href = "/error?e_code=trunkItem_03";
      });
  },
});

export const { setIsLoading } = trunkItemSlice.actions;

export const selectIsLoading = (state: RootState) => state.trunkItem.isLoading;

export const selectTrunks = (state: RootState) => state.trunkItem.trunks;
export const selectTrunkItems = (state: RootState) => state.trunkItem.trunkItems;

export default trunkItemSlice.reducer;
