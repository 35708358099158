import { ChangeEvent, FC, memo, ReactNode, useMemo, useState } from "react";

import { formatDate, getNMonthDate, getNMonthEndDate } from "common/common";

type Props = {
  setDate: Function;
};

/**
 * 退去希望日セレクト
 * @param Props
 * @returns
 */
const LeaveDateSelect: FC<Props> = ({ setDate }) => {
  const [year, setYear] = useState<number>(0);
  const [month, setMonth] = useState<number>(0);

  const options: ReactNode[] = useMemo(() => {
    const options: ReactNode[] = [<option key={999} value="" hidden></option>];

    const date: Date = getNMonthDate(2);
    const min: Date = getNMonthDate(2);
    const max: Date = getNMonthEndDate(2);
    setYear(min.getFullYear());
    setMonth(min.getMonth() + 1);

    for (let i = min.getDate(); i <= max.getDate(); i++) {
      options.push(
        <option key={i} value={formatDate(date)}>
          {i}
        </option>
      );
      date.setDate(date.getDate() + 1);
    }

    return options;
  }, []);

  return (
    <div className="mx-auto">
      <label className="block mb-[-6px]">退去希望日</label>
      <small className="text-red-500">※2ヶ月後の月内から選択可能です</small>
      <div className="flex items-center">
        <div className="w-32 [&>span]:mx-2 text-lg text-center">
          {year}
          <span>年</span>
          {month}
          <span>月</span>
        </div>
        <div className="flex">
          <select
            className="w-20 rounded-lg"
            onChange={(e: ChangeEvent<HTMLSelectElement>) => setDate(e.target.value)}
          >
            {options}
          </select>
          <div className="flex items-center ml-2">日</div>
        </div>
      </div>
    </div>
  );
};

export default memo(LeaveDateSelect);
