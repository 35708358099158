import { FC, ReactNode } from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";

import Header from "components/Header";
import Footer from "components/Footer";

type Props = {
  title: string;
  children: ReactNode;
};

/**
 * 共通レイアウト
 * @param Props
 * @returns
 */
const Layout: FC<Props> = ({ title, children }) => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{title} | NANDOYA</title>
        </Helmet>
      </HelmetProvider>
      <Header />
      <main className="min-h-[calc(100vh-64px-128px)] sm:min-h-[calc(100vh-80px-128px)]">
        {children}
      </main>
      <Footer />
    </>
  );
};

export default Layout;
