import { v4 as uuidv4 } from "uuid";
import imageCompression from "browser-image-compression";

import { CONTRACT_STATUS_STRS } from "common/constants";

/**
 * 現在のタイムスタンプを取得
 * @returns
 */
export const getTimestampNow = (): number => {
  const date: Date = new Date();
  return date.getTime();
};

/**
 * ステータスID => ステータス内容文字列
 * @param contractStatusId
 * @returns string
 */
export const convertCSIdIntoCS = (contractStatusId: number): string => {
  if (contractStatusId <= CONTRACT_STATUS_STRS.length) {
    return CONTRACT_STATUS_STRS[contractStatusId];
  } else {
    return "";
  }
};

/**
 * UUID作成
 * @returns string UUID
 */
export const createUuid = (): string => {
  return uuidv4();
};

/**
 * 現在日時からn日を足した年月日を返却
 * @param n
 * @returns Date
 */
export const addNDates = (n: number): Date => {
  const date: Date = new Date();
  date.setDate(date.getDate() + n);
  return date;
};

/**
 * n年後の年月日を返却（うるう年考慮）
 * @param baseDateStr
 * @param n
 * @returns string yyyy/mm/dd
 */
export const addNYears = (baseDateStr: string, n: number): string => {
  const date: Date = new Date(baseDateStr);
  date.setFullYear(date.getFullYear() + n);
  return formatDate(date);
};

/**
 * 現在日時のnヶ月後を返却
 * 存在しない場合はn月の末日を返却
 * @param n
 * @returns Date
 */
export const getNMonthDate = (n: number): Date => {
  const date: Date = new Date();

  // 基準の年月日を取得
  const year: number = date.getFullYear();
  const month: number = date.getMonth();
  const day: number = date.getDate();

  const nextDate: Date = new Date(year, month);
  nextDate.setMonth(nextDate.getMonth() + n);
  const endDay: number = new Date(nextDate.getFullYear(), nextDate.getMonth() + 1, 0).getDate();

  // 日にちが該当月に無い場合はその月の末日を設定する
  if (endDay < day) {
    nextDate.setDate(endDay);
  } else {
    nextDate.setDate(day);
  }

  return nextDate;
};

/**
 * yyyy/mm/dd形式に成形
 * @param date
 * @returns
 */
export const formatDate = (date: Date): string => {
  return `${date.getFullYear()}/${padTwo(date.getMonth() + 1)}/${padTwo(date.getDate())}`;
};

/**
 * yyyy年mm月dd日形式に成形
 * @param date
 * @returns
 */
export const formatDateofJp = (date: Date): string => {
  return `${date.getFullYear()}年${padTwo(date.getMonth() + 1)}月${padTwo(date.getDate())}日`;
};

/**
 * nヶ月後の月末を返却
 * @param n
 * @returns number
 */
export const getNMonthEndDate = (n: number): Date => {
  const date: Date = new Date();
  const resDate: Date = new Date(date.getFullYear(), date.getMonth() + (1 + n), 0);
  return resDate;
};

/**
 * 指定年月の月末日を返却
 * @param year
 * @param month
 * @returns number
 */
export const getEndDate = (year: number, month: number): number => {
  const resDate: Date = new Date(year, month, 0);
  return resDate.getDate();
};

/**
 * ２桁0埋めで返す
 * @param n
 * @returns ex) 1 => 01,  10 => 10
 */
export const padTwo = (n: number | string) => {
  return n < 10 ? "0" + n : n;
};

/**
 * クエリパラメータ全取得
 * @param searchParams
 * @returns
 */
export const getQParams = (searchParams: URLSearchParams): { [key: string]: string } => {
  const qParams: { [key: string]: string } = {};
  searchParams.forEach((value: string, key: string) => {
    if (key && value) {
      qParams[key] = value;
    }
  });
  return qParams;
};

/**
 * 画像を圧縮する
 * @param file
 * @returns File
 */
export const compressImg = async (file: File): Promise<File> => {
  const options = {
    maxSizeMB: 1, // 1MB
    maxWidthOrHeight: 1000,
  };
  return await imageCompression(file, options);
};

/**
 * FileReaderの同期ver
 * @param blob
 * @returns
 */
export const readAsDataURL = (blob: Blob): Promise<any> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = () => {
      reject(reader.error);
    };
    reader.readAsDataURL(blob);
  });
};
