import { FC } from "react";

import Layout from "components/Layout";
import Title from "components/common/Title";
import MansionImg from "components/trunk/MansionImg";
import TrunkTable from "components/trunk/TrunkTable";
import MansionInfo from "components/trunk/MansionInfo";
import Loader from "components/common/Loader";
import Pagenation from "components/common/Pagenation";

import { useTrunkDetail } from "hooks/trunkDetailHooks";

type Props = {
  title: string;
};

/**
 * トランク一覧画面
 * @param Props
 * @returns
 */
const Detail: FC<Props> = ({ title }) => {
  const {
    apartment,
    trunk,
    pageTokens,
    nextToken,
    isLoading,
    isFirstLoading,
    clickNext,
    clickPrev,
    clickApply,
  } = useTrunkDetail();

  return (
    <Layout title={title}>
      {isFirstLoading && isLoading ? (
        <Loader isActive={isLoading} />
      ) : (
        <>
          {apartment.apartmentNumber && (
            <div className="pb-16">
              <Title>{apartment.apartmentName}</Title>
              <MansionImg imgs={apartment.imageUrls} />
              <MansionInfo apartment={apartment} />

              <div className="max-w-screen-lg w-4/5 mx-auto">
                <TrunkTable trunks={trunk.data} clickApply={clickApply} />
              </div>

              <div className="max-w-3xl w-4/5 mx-auto">
                <Pagenation
                  pageNum={pageTokens.length + 1}
                  isDisabledNext={nextToken ? false : true}
                  isDisabledPrev={pageTokens.length === 0}
                  clickNext={clickNext}
                  clickPrev={clickPrev}
                />
              </div>
            </div>
          )}
        </>
      )}
    </Layout>
  );
};

export default Detail;
