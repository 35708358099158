import { FC } from "react";
import { Link } from "react-router-dom";

import { CONTRACT_STATUS, Q_PARAMS } from "common/constants";
import { PAGES } from "common/PAGES";
import Layout from "components/Layout";
import Loader from "components/common/Loader";
import Title from "components/common/Title";
import ContentCard from "components/common/ContentCard";
import TwoColContents, { TwoColRow } from "components/common/TwoColContents";
import MansionInfo from "components/trunk/MansionInfo";
import Pagenation from "components/common/Pagenation";

import { useMypageTop } from "hooks/mypageTopHooks";

type Props = {
  title: string;
};

/**
 * マイページTOP画面
 * @param Props
 * @returns
 */
const Top: FC<Props> = ({ title }) => {
  const {
    apartment,
    userInfo,
    contractedTrunk,
    trunkRows,
    pageTokens,
    nextToken,
    isLoading,
    clickLeaveImgs,
    clickLeave,
    clickNext,
    clickPrev,
  } = useMypageTop();

  return (
    <Layout title={title}>
      {!userInfo.email || isLoading ? (
        <Loader isActive={isLoading} />
      ) : (
        <div className="pb-16">
          <Title>トランクルーム詳細</Title>

          {apartment.apartmentNumber && (
            <>
              <MansionInfo apartment={apartment} isMansionName />

              {contractedTrunk.data.length > 0 ? (
                trunkRows.map((rows: TwoColRow[], index: number) => {
                  return (
                    <ContentCard key={index}>
                      <TwoColContents rows={rows} />
                      {contractedTrunk.data[index].trunkCurrentStatus ===
                      CONTRACT_STATUS.CONTRACTED ? (
                        <div className="text-right">
                          <span
                            className="link-a-tag"
                            onClick={() => clickLeave(contractedTrunk.data[index])}
                          >
                            退去申請はこちら
                          </span>
                        </div>
                      ) : (
                        contractedTrunk.data[index].trunkCurrentStatus ===
                          CONTRACT_STATUS.PLAN_TO_CANCEL && (
                          <div className="text-right">
                            <span
                              className="link-a-tag"
                              onClick={() => clickLeaveImgs(contractedTrunk.data[index])}
                            >
                              退去完了写真の登録
                            </span>
                          </div>
                        )
                      )}
                    </ContentCard>
                  );
                })
              ) : (
                <div className="mt-8 w-4/5 mx-auto sm:w-2/5">
                  <p>契約中、もしくは申請中のトランクルームはありません。</p>
                  <Link
                    className="link-a-tag font-bold text-lg"
                    to={`${PAGES.TRUNK_DETAIL.PATH}?${Q_PARAMS.APARTMENT_NUMBER}=${userInfo.apartmentNumber}`}
                  >
                    契約はこちらから
                  </Link>
                </div>
              )}
            </>
          )}

          {contractedTrunk.data.length > 0 && (
            <div className="max-w-xl w-4/5 mx-auto">
              <Pagenation
                pageNum={pageTokens.length + 1}
                isDisabledNext={nextToken ? false : true}
                isDisabledPrev={pageTokens.length === 0}
                clickNext={clickNext}
                clickPrev={clickPrev}
              />
            </div>
          )}
        </div>
      )}
    </Layout>
  );
};

export default Top;
