import { FC, memo } from "react";

export type TwoColRow = {
  title: string;
  content: string | number;
};

type Props = {
  rows: TwoColRow[];
};

/**
 * 2列表示
 * @param Props
 * @returns
 */
const TwoColContents: FC<Props> = ({ rows }) => {
  return (
    <ul className="mb-4">
      {rows.map((row: TwoColRow, index: number) => {
        return (
          <li key={index} className="flex mt-4">
            <div className="flex w-1/2 justify-center items-center">{row.title}</div>
            <div className="w-1/2 ml-4">
              {typeof row.content === "string" && row.content.substring(0, 4) === "http" ? (
                <a className="link-a-tag" href={row.content} target="_blank" rel="noreferrer">
                  {row.content}
                </a>
              ) : (
                row.content
              )}
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default memo(TwoColContents);
