import { FC, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { useAppDispatch } from "app/hooks";
import { PAGES } from "common/PAGES";
import Layout from "components/Layout";
import Title from "components/common/Title";
import { unsetIsRendering } from "ducks/trunk/slice";

type Props = {
  title: string;
};

/**
 * トランク退去完了写真の登録完了画面
 * @returns
 */
const LeaveImgsUploadEnd: FC<Props> = ({ title }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [trunkNumber] = useState<number>(location.state as number);

  useEffect(() => {
    dispatch(unsetIsRendering());
  }, [dispatch]);

  return (
    <Layout title={title}>
      <Title>トランクルーム退去完了写真の登録完了</Title>

      <div className="mt-12 w-11/12 max-w-lg mx-auto sm:mt-20 text-lg">
        <p>トランク番号{trunkNumber}の退去完了写真の登録が完了しました。</p>
      </div>

      <div className="mt-12 text-center text-base">
        <Link className="link-a-tag" to={PAGES.MYPAGE_TOP.PATH}>
          マイページへ
        </Link>
      </div>
    </Layout>
  );
};

export default LeaveImgsUploadEnd;
