import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import authReducer from "ducks/auth/slice";
import companyReducer from "ducks/company/slice";
import trunkReducer from "ducks/trunk/slice";
import trunkItemReducer from "ducks/trunkItem/slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    company: companyReducer,
    trunk: trunkReducer,
    trunkItem: trunkItemReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ["payload.files", "meta.arg.idImgs", "meta.arg.imgs", "meta.arg.img"],
        ignoredPaths: ["auth.idImgs"],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
