import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "app/hooks";
import { convertCSIdIntoCS, getQParams } from "common/common";
import { PAGES } from "common/PAGES";
import { CONTRACT_STATUS, LOCAL_STORAGE_KEYS, Q_PARAMS, URL_PARAMS } from "common/constants";
import { TwoColRow } from "components/common/TwoColContents";
import {
  fetchAsyncGetApartments,
  fetchAsyncGetTrunksByEmail,
  selectApartment,
  selectContractedTrunk,
  selectIsLoading,
  selectNextToken,
  setTargetTrunk,
} from "ducks/trunk/slice";
import { Apartment, Trunk, TrunkData } from "ducks/trunk/type";
import { UserInfo } from "ducks/auth/type";
import { selectUserInfo } from "ducks/auth/slice";

/**
 * マイページTOP画面用フックス
 * @returns
 */
export const useMypageTop = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const isLoading: boolean = useAppSelector(selectIsLoading);
  const userInfo: UserInfo = useAppSelector(selectUserInfo);
  const apartment: Apartment = useAppSelector(selectApartment);
  const contractedTrunk: Trunk = useAppSelector(selectContractedTrunk);
  const nextToken: string = useAppSelector(selectNextToken);

  const [pageTokens, setPageTokens] = useState<string[]>([]);

  /** next pageクリック */
  const clickNext = useCallback(() => {
    const qParams: { [key: string]: string } = getQParams(searchParams);
    qParams[Q_PARAMS.PAGE_TOKENS] = pageTokens ? [...pageTokens, nextToken].join(" ") : nextToken;

    setSearchParams(qParams);
    window.scrollTo(0, 0);
  }, [nextToken, pageTokens, searchParams, setSearchParams]);

  /** previous pageクリック */
  const clickPrev = useCallback(() => {
    if (pageTokens.length > 0) {
      const qParams: { [key: string]: string } = getQParams(searchParams);
      pageTokens.pop();
      qParams[Q_PARAMS.PAGE_TOKENS] = pageTokens.join(" ");
      setSearchParams(qParams);
      window.scrollTo(0, 0);
    }
  }, [pageTokens, searchParams, setSearchParams]);

  /** 退去申請ボタン */
  const clickLeave = useCallback(
    (trunk: TrunkData) => {
      dispatch(setTargetTrunk(trunk));
      localStorage.setItem(LOCAL_STORAGE_KEYS.TRUNK_NUMBER, trunk.trunkNumber);
      navigate(PAGES.LEAVE_APPLICATION.PATH.replace(URL_PARAMS.TRUNK_NUMBER, trunk.trunkNumber));
    },
    [dispatch, navigate]
  );

  /** 退去完了写真の登録ボタン */
  const clickLeaveImgs = useCallback(
    (trunk: TrunkData) => {
      dispatch(setTargetTrunk(trunk));
      navigate(PAGES.LEAVE_IMGS_UPLOAD.PATH.replace(URL_PARAMS.TRUNK_NUMBER, trunk.trunkNumber));
    },
    [dispatch, navigate]
  );

  /** 契約・申請したトランク一覧取得関数 */
  const fetchTrunks = useCallback(() => {
    // クエリパラメータ取得
    const pageTokensStr: string | null = searchParams.get(Q_PARAMS.PAGE_TOKENS);
    let tmpPageTokens: string[] = [];
    if (pageTokensStr) {
      tmpPageTokens = pageTokensStr.split(" ");
    }
    setPageTokens(tmpPageTokens);
    const exclusiveStartKey: string | undefined = tmpPageTokens[tmpPageTokens.length - 1];

    // トランク情報取得
    const contractorEmail: string = userInfo.email;
    if (exclusiveStartKey) {
      dispatch(fetchAsyncGetTrunksByEmail({ contractorEmail, exclusiveStartKey }));
    } else {
      dispatch(fetchAsyncGetTrunksByEmail({ contractorEmail }));
    }
  }, [dispatch, searchParams, userInfo.email]);

  const trunkRows: TwoColRow[][] = useMemo(() => {
    const tmp: TwoColRow[][] = [];
    contractedTrunk.data.forEach((trunk: TrunkData, index: number) => {
      tmp.push([
        {
          title: "トランク番号",
          content: trunk.trunkNumber,
        },
        {
          title: "契約状況",
          content:
            trunk.trunkCurrentStatus === CONTRACT_STATUS.PLAN_TO_CANCEL
              ? `${convertCSIdIntoCS(trunk.trunkCurrentStatus)}(${trunk.contractEndedAt})`
              : convertCSIdIntoCS(trunk.trunkCurrentStatus),
        },
        {
          title: "利用開始日",
          content: trunk.contractStartedAt,
        },
      ]);

      // 解約予定時のみ退去完了写真の登録が未登録/登録済みを表示する
      if (trunk.trunkCurrentStatus === CONTRACT_STATUS.PLAN_TO_CANCEL) {
        tmp[index].push({
          title: "退去完了写真",
          content: trunk.latestImages.email ? "登録済み" : "未登録",
        });
      }
    });
    return tmp;
  }, [contractedTrunk.data]);

  /** マンション情報取得 */
  useEffect(() => {
    userInfo.apartmentNumber &&
      dispatch(fetchAsyncGetApartments({ apartmentNumber: userInfo.apartmentNumber }));
  }, [dispatch, userInfo.apartmentNumber]);

  /** ユーザに紐づく契約したトランク情報取得 */
  useEffect(() => {
    userInfo.email && fetchTrunks();
  }, [fetchTrunks, userInfo.email]);

  return {
    apartment,
    userInfo,
    contractedTrunk,
    trunkRows,
    pageTokens,
    nextToken,
    isLoading,
    clickLeaveImgs,
    clickLeave,
    clickNext,
    clickPrev,
  };
};
