import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "app/hooks";
import { PAGES } from "common/PAGES";
import { LOCAL_STORAGE_KEYS, Q_PARAMS } from "common/constants";
import { getQParams } from "common/common";
import {
  fetchAsyncGetApartments,
  fetchAsyncGetTrunksByAptNumber,
  selectApartment,
  selectIsLoading,
  selectNextToken,
  selectTrunk,
  setTargetTrunk,
} from "ducks/trunk/slice";
import { Apartment, Trunk, TrunkData } from "ducks/trunk/type";

/**
 * トランク詳細画面用フックス
 * @returns
 */
export const useTrunkDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const apartmentNumber: string | null = searchParams.get(Q_PARAMS.APARTMENT_NUMBER);

  const isLoading: boolean = useAppSelector(selectIsLoading);
  const apartment: Apartment = useAppSelector(selectApartment);
  const trunk: Trunk = useAppSelector(selectTrunk);
  const nextToken: string = useAppSelector(selectNextToken);

  const [pageTokens, setPageTokens] = useState<string[]>([]);
  /** 初回レンダリング時のみローディング表示用フラグ */
  const [isFirstLoading, setIsFirstLoading] = useState<boolean>(true);

  /** next pageクリック */
  const clickNext = useCallback(() => {
    const qParams: { [key: string]: string } = getQParams(searchParams);
    qParams[Q_PARAMS.PAGE_TOKENS] = pageTokens ? [...pageTokens, nextToken].join(" ") : nextToken;

    setSearchParams(qParams);
    window.scrollTo(0, 0);
  }, [pageTokens, searchParams, setSearchParams, nextToken]);

  /** previous pageクリック */
  const clickPrev = useCallback(() => {
    if (pageTokens.length > 0) {
      const qParams: { [key: string]: string } = getQParams(searchParams);
      pageTokens.pop();
      qParams[Q_PARAMS.PAGE_TOKENS] = pageTokens.join(" ");
      setSearchParams(qParams);
      window.scrollTo(0, 0);
    }
  }, [pageTokens, searchParams, setSearchParams]);

  /** トランク一覧取得関数 */
  const fetchTrunks = useCallback(() => {
    // クエリパラメータ取得
    const pageTokensStr: string | null = searchParams.get(Q_PARAMS.PAGE_TOKENS);
    let tmpPageTokens: string[] = [];
    if (pageTokensStr) {
      tmpPageTokens = pageTokensStr.split(" ");
    }
    setPageTokens(tmpPageTokens);
    const exclusiveStartKey: string | undefined = tmpPageTokens[tmpPageTokens.length - 1];

    // トランク情報取得
    if (apartmentNumber) {
      if (exclusiveStartKey) {
        dispatch(fetchAsyncGetApartments({ apartmentNumber, exclusiveStartKey }));
        dispatch(fetchAsyncGetTrunksByAptNumber({ apartmentNumber, exclusiveStartKey }));
      } else {
        dispatch(fetchAsyncGetApartments({ apartmentNumber }));
        dispatch(fetchAsyncGetTrunksByAptNumber({ apartmentNumber }));
      }
    }
  }, [searchParams, apartmentNumber, dispatch]);

  /** 申込みボタン押下 */
  const clickApply = useCallback(
    (targetTrunk: TrunkData) => {
      if (apartmentNumber) {
        dispatch(setTargetTrunk(targetTrunk));
        localStorage.setItem(LOCAL_STORAGE_KEYS.TRUNK_NUMBER, targetTrunk.trunkNumber);
        window.location.href = `${PAGES.TRUNK_APPLICATION.PATH}?${Q_PARAMS.APARTMENT_NUMBER}=${apartmentNumber}`;
      }
    },
    [dispatch, apartmentNumber]
  );

  /** マンション情報を取得 */
  useEffect(() => {
    if (apartmentNumber) {
      fetchTrunks();
    } else {
      navigate(PAGES.MYPAGE_TOP.PATH);
    }
  }, [navigate, apartmentNumber, fetchTrunks]);

  useEffect(() => {
    isFirstLoading && trunk.data.length > 0 && setIsFirstLoading(false);
  }, [isFirstLoading, trunk.data.length]);

  return {
    apartment,
    trunk,
    pageTokens,
    nextToken,
    isLoading,
    isFirstLoading,
    clickApply,
    clickNext,
    clickPrev,
  };
};
