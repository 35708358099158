import { FC } from "react";
import { Link } from "react-router-dom";

import { useAppSelector } from "app/hooks";
import { PAGES } from "common/PAGES";
import { Q_PARAMS } from "common/constants";
import Layout from "components/Layout";
import Title from "components/common/Title";

import { UserInfo } from "ducks/auth/type";
import { selectUserInfo } from "ducks/auth/slice";

type Props = {
  title: string;
};

/**
 * 申込みエラー画面
 * @param Props
 * @returns
 */
const ApplicationError: FC<Props> = ({ title }) => {
  const userInfo: UserInfo = useAppSelector(selectUserInfo);

  return (
    <Layout title={title}>
      <Title>トランクルームの利用申込エラー</Title>

      <div className="mt-12 w-11/12 max-w-lg mx-auto sm:mt-20 text-lg">
        <p>申し訳ございません。トランクルームの利用申込みに失敗しました。</p>
        <p>再度利用申込み画面から申し込みをお願い致します。</p>
      </div>

      <div className="mt-12 text-center text-base">
        <Link
          className="link-a-tag"
          to={`${PAGES.TRUNK_DETAIL.PATH}?${Q_PARAMS.APARTMENT_NUMBER}=${userInfo.apartmentNumber}`}
        >
          トランクルーム申し込み画面へ
        </Link>
      </div>
    </Layout>
  );
};

export default ApplicationError;
