import { FC, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { useAppSelector } from "app/hooks";
import { HEADER_LOGO, Q_PARAMS } from "common/constants";
import { PAGES } from "common/PAGES";
import Hamburger from "components/header/Hamburger";
import { UserInfo } from "ducks/auth/type";
import { selectUserInfo } from "ducks/auth/slice";

export type PathOfHeader = {
  name: string;
  path: string;
  qParams?: string;
  isSpa: boolean;
};

/**
 * 共通ヘッダー
 * @returns
 */
const Header: FC = () => {
  const userInfo: UserInfo = useAppSelector(selectUserInfo);
  const { pathname } = useLocation();
  const paths: PathOfHeader[] = useMemo(
    () => [
      {
        name: "トランク利用申込み",
        path: PAGES.TRUNK_DETAIL.PATH,
        qParams: `${Q_PARAMS.APARTMENT_NUMBER}=${userInfo.apartmentNumber}`,
        isSpa: true,
      },
      {
        name: "マイページ",
        path: PAGES.MYPAGE_TOP.PATH,
        isSpa: true,
      },
      {
        name: "契約者情報",
        path: PAGES.CONTRACTOR_DETAIL.PATH,
        isSpa: false,
      },
      {
        name: "収納物一覧",
        path: PAGES.TRUNK_ITEMS.PATH,
        isSpa: true,
      },
    ],
    [userInfo]
  );

  return (
    <header className="w-full flex justify-between h-16 bg-main text-slate-50 sm:h-20 shadow-lg">
      <div className="w-28 sm:w-36 mt-2 ml-4 sm:ml-12">
        <img src={HEADER_LOGO} alt="logo" />
      </div>

      <Hamburger paths={paths} currentPath={pathname} />
    </header>
  );
};

export default Header;
