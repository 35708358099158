import { FC } from "react";

import Button from "components/common/Button";
import Input from "components/common/Input";
import Title from "components/common/Title";
import Layout from "components/Layout";
import Loader from "components/common/Loader";
import LeaveDateSelect from "components/mypage/LeaveDateSelect";

import { useLeageApplication } from "hooks/leaveApplication";

type Props = {
  title: string;
};

/**
 * 退去申請画面
 * @param Props
 * @returns
 */
const LeaveApplication: FC<Props> = ({ title }) => {
  const { trunkNumber, preferredDate, isLoading, setPreferredDate, clickApply, clickBack } =
    useLeageApplication();

  return (
    <Layout title={title}>
      {isLoading ? (
        <Loader isActive={isLoading} />
      ) : (
        <div className="pb-16">
          <Title>退去申請</Title>

          <div className="w-4/5 max-w-lg mx-auto mt-8 [&>div]:mt-8">
            <Input
              label="トランク番号"
              type="text"
              id="trunk-name"
              defaultValue={trunkNumber}
              disabled
            />
            <LeaveDateSelect setDate={setPreferredDate} />
          </div>

          <div className="[&>button]:sm:mx-14 [&>button]:mt-10  sm:flex sm:justify-center sm:flex-row-reverse sm:mt-6">
            <Button onClick={clickApply} disabled={preferredDate ? false : true}>
              上記の内容で申し込む
            </Button>
            <Button onClick={clickBack} subMode>
              戻る
            </Button>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default LeaveApplication;
