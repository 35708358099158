import { FC } from "react";

import Title from "components/common/Title";
import Layout from "components/Layout";
import ItemRegister from "components/trunkItems/ItemRegister";
import Loader from "components/common/Loader";
import Items from "components/trunkItems/Items";
import DispTrunkSelect from "components/trunkItems/DispTrunkSelect";

import { useTrunkItems } from "hooks/trunkItemsHooks";

type Props = {
  title: string;
};

/**
 * 収納物一覧画面
 * @param Props
 * @returns
 */
const TrunkItems: FC<Props> = ({ title }) => {
  const { isLoading, trunks, dispTrunkItems, dispTrunkNum, setDispTrunkNum, getTrunkItems } =
    useTrunkItems();

  return (
    <Layout title={title}>
      {isLoading ? (
        <Loader isActive={isLoading} />
      ) : (
        <>
          <Title>収納物一覧</Title>

          <div className="flex justify-center items-center mt-4">
            <ItemRegister
              trunks={trunks}
              trunkItems={dispTrunkItems}
              getTrunkItems={getTrunkItems}
            />
          </div>

          <div className="mx-auto w-52">
            <DispTrunkSelect
              trunks={trunks}
              dispTrunkNum={dispTrunkNum}
              setDispTrunkNum={setDispTrunkNum}
            />
            <div className="text-right mr-4">件数 : {dispTrunkItems.length}</div>
          </div>

          <div className="my-8">
            <Items trunkItems={dispTrunkItems} getTrunkItems={getTrunkItems} />
          </div>
        </>
      )}
    </Layout>
  );
};

export default TrunkItems;
