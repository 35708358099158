import { FC } from "react";

import { MAX_LEAVE_IMG_MAX } from "common/constants";
import ImgsModal from "components/common/ImgsModal";
import Loader from "components/common/Loader";
import Title from "components/common/Title";
import Layout from "components/Layout";
import Button from "components/common/Button";
import Input from "components/common/Input";

import { useLeaveImgsUpload } from "hooks/LeaveImgsUploadHooks";

type Props = {
  title: string;
};

/**
 * 退去完了写真アップロード画面
 * @returns
 */
const LeaveImgsUpload: FC<Props> = ({ title }) => {
  const {
    targetTrunk,
    imgFiles,
    imgSrcs,
    isError,
    isOpenImgsModal,
    isLoading,
    setIsOpenImgsModal,
    closeImgsModal,
    changeImgs,
    clickUpload,
    clickBack,
  } = useLeaveImgsUpload();

  return (
    <Layout title={title}>
      {isLoading ? (
        <Loader isActive={isLoading}></Loader>
      ) : (
        <>
          <Title>トランクルーム退去完了写真の登録</Title>

          <div className="w-4/5 max-w-lg mx-auto mt-8 [&>div]:mt-8">
            <Input
              label="トランク番号"
              type="text"
              id="trunk-name"
              defaultValue={targetTrunk?.trunkNumber}
              disabled
            />
          </div>

          <div className="flex justify-center items-center mt-12">
            <label className="nandoya-btn hover:bg-main hover:opacity-70 bg-main text-white flex justify-center p-0">
              <input
                className="hidden"
                type="file"
                accept="image/*"
                onChange={changeImgs}
                multiple
              />
              写真を選択
            </label>
          </div>

          <div className="w-8/12 max-w-lg mx-auto ">
            {isError && <p className="text-red-500">※登録できる写真は3枚までです</p>}
            <div className="flex">
              <span className="block">※</span>
              <p>トランクを空にした状態が確認できる写真の登録をお願いします。</p>
            </div>
            <div className="flex">
              <span className="block">※</span>
              <p>写真は{MAX_LEAVE_IMG_MAX}枚まで登録可能です。</p>
            </div>
          </div>

          {imgSrcs.length > 0 && (
            <div className="text-center mt-4">
              <p className="link-a-tag inline" onClick={() => setIsOpenImgsModal(true)}>
                選択した写真を確認
              </p>
            </div>
          )}

          <div className="[&>button]:sm:mx-14 [&>button]:mt-10 sm:flex sm:justify-center sm:flex-row-reverse sm:mt-6 mb-8">
            <Button onClick={clickUpload} disabled={!imgFiles.length}>
              登録
            </Button>
            <Button onClick={clickBack} subMode>
              戻る
            </Button>
          </div>

          <ImgsModal isOpen={isOpenImgsModal} close={closeImgsModal} srcs={imgSrcs} />
        </>
      )}
    </Layout>
  );
};

export default LeaveImgsUpload;
