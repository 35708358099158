import { FC, memo, useState } from "react";

import { useAppDispatch } from "app/hooks";
import Button from "components/common/Button";
import DelCheckModal from "components/trunkItems/DelCheckModal";

import { TrunkItems, TrunkItem } from "ducks/trunkItem/type";
import { fetchAsyncDeleteTrunkItem } from "ducks/trunkItem/slice";

type Props = {
  trunkItems: TrunkItems;
  getTrunkItems: Function;
};

/**
 * 収納物一覧
 * @returns
 */
const Items: FC<Props> = ({ trunkItems, getTrunkItems }) => {
  const dispatch = useAppDispatch();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [targetItem, setTargetItem] = useState<TrunkItem | null>(null);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  /** 削除ボタンクリック */
  const clickDelete = async (item: TrunkItem) => {
    setTargetItem(item);
    setIsModalOpen(true);
  };

  /** 削除実行 */
  const exeDelete = async () => {
    if (targetItem) {
      setIsDeleting(true);
      await dispatch(fetchAsyncDeleteTrunkItem(targetItem.id));
      await getTrunkItems();
      closeModal();
      setIsDeleting(false);
    }
  };

  /** モーダルクローズ */
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="max-w-7xl mx-auto sm:grid sm:gap-y-2 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
      {trunkItems.map((item: TrunkItem, index: number) => {
        return (
          <div key={index} className="mb-8">
            <div className="flex justify-center items-center mx-auto h-40 w-4/5 border shadow-md">
              <img
                className="max-h-full max-w-full"
                src={item.imageUrl}
                alt={`trunk-item-${index}`}
              />
            </div>

            <ul className="mx-auto m-2 w-4/5 pl-4 [&>li]:text-gray-500">
              <li className="!text-black">{item.name}</li>
              <li>{item.notificationMonth}月</li>
              <li>{item.trunkNumber}</li>
            </ul>

            <div className="[&>button]:h-12 [&>button]:w-36">
              <Button onClick={() => clickDelete(item)} subMode>
                削除
              </Button>
            </div>
          </div>
        );
      })}

      <DelCheckModal
        isOpen={isModalOpen}
        item={targetItem}
        isDeleting={isDeleting}
        close={closeModal}
        exeDelete={exeDelete}
      />
    </div>
  );
};

export default memo(Items);
