import { FC, memo, ReactNode } from "react";

type Props = {
  children: ReactNode;
};

/**
 * 共通コンテンツ表示用カード
 * @param Props
 * @returns
 */
const ContentCard: FC<Props> = ({ children }) => {
  return (
    <div className="overflow-hidden rounded-lg bg-gray-200 shadow max-w-xl mx-auto mt-6">
      <div className="px-4 py-5 sm:p-6 whitespace-pre-wrap break-words">{children}</div>
    </div>
  );
};

export default memo(ContentCard);
