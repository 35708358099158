import { Auth, Amplify } from "aws-amplify";
import { EX_AGW_NAME, IN_AGW_NAME } from "common/constants";

/**
 * 初期処理フック
 * @returns
 **/
export const useInitialHooks = () => {
  const initialize = () => {
    Amplify.configure({
      Auth: {
        region: "ap-northeast-1",
        userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
        oauth: {
          domain: process.env.REACT_APP_COGNITO_DOMAIN,
          redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN,
          redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGNOUT,
          scope: ["openid", "email", "profile"],
          responseType: "code",
        },
      },
      API: {
        endpoints: [
          {
            name: IN_AGW_NAME,
            endpoint: process.env.REACT_APP_AGW_ENDPOINT,
            custom_header: async () => {
              return {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
              };
            },
          },
          {
            name: EX_AGW_NAME,
            endpoint: process.env.REACT_APP_AGW_ENDPOINT,
          },
        ],
      },
    });
  };

  return { initialize };
};
