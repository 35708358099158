import { AuthState } from "ducks/auth/type";
export const initialState: AuthState = {
  isLoading: false,
  userInfo: {
    email: "",
    userName: "",
    userNameKana: "",
    birthdate: "",
    address: "",
    phoneNumber: "",
    customerId: "",
    apartmentNumber: "",
    apartmentName: "",
    trunkNumbers: [],
    idDocNames: [],
    isAuthedCard: false,
    isUploadedIdDoc: false,
    isContracted: 0,
  },
  idImgs: [],
  idImgSrcs: [],
  aptName: "",
};
