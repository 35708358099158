import { FC, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAppDispatch } from "app/hooks";
import { PAGES } from "common/PAGES";
import Layout from "components/Layout";
import Title from "components/common/Title";
import { unsetTargetTrunk } from "ducks/trunk/slice";

type Props = {
  title: string;
};

/**
 * 退去申込み完了画面
 * @param Props
 * @returns
 */
const LeaveApplicationEnd: FC<Props> = ({ title }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(unsetTargetTrunk());
  }, [dispatch]);

  return (
    <Layout title={title}>
      <Title>トランクルームの退去申込完了</Title>

      <div className="mt-12 w-11/12 max-w-lg mx-auto sm:mt-20 text-lg">
        <p>トランクルーム退去のお申し込みを受け付けました。</p>
        <p>解約予定日までに、マイページから退去完了写真の登録をお願い致します。</p>
      </div>

      <div className="mt-12 text-center text-base">
        <Link className="link-a-tag" to={PAGES.MYPAGE_TOP.PATH}>
          マイページへ
        </Link>
      </div>
    </Layout>
  );
};

export default LeaveApplicationEnd;
