import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { createClient } from "microcms-js-sdk";

import { RootState } from "app/store";
import { CMS_API_DOMAIN, CMS_API_KEY, CMS_API_PATHS } from "common/constants";
import { initialState } from "ducks/company/initialState";
import { CompanyState } from "ducks/company/type";

/**
 * 会社概要の取得
 **/
export const fetchAsyncGetCompanyProf = createAsyncThunk(
  "company/getCompanyProf",
  async (_, thunkAPI) => {
    try {
      const client = createClient({ serviceDomain: CMS_API_DOMAIN, apiKey: CMS_API_KEY });
      return await client.get({
        endpoint: CMS_API_PATHS.COMPANY,
        queries: { fields: "title,content", limit: 50 },
      });
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**
 * 特定商取引法に基づく表記の取得
 **/
export const fetchAsyncGetCompanyLaw = createAsyncThunk(
  "company/getCompanyLaw",
  async (_, thunkAPI) => {
    try {
      const client = createClient({ serviceDomain: CMS_API_DOMAIN, apiKey: CMS_API_KEY });
      return await client.get({
        endpoint: CMS_API_PATHS.LAW,
        queries: { fields: "title,content", limit: 50 },
      });
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**
 * 利用規約の取得
 **/
export const fetchAsyncGetTos = createAsyncThunk("company/getTos", async (_, thunkAPI) => {
  try {
    const client = createClient({ serviceDomain: CMS_API_DOMAIN, apiKey: CMS_API_KEY });
    return await client.get({ endpoint: CMS_API_PATHS.TOS, queries: { fields: "tos" } });
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

/** 会社情報スライス */
export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<CompanyState>) => {
    builder
      .addCase(
        fetchAsyncGetCompanyProf.fulfilled,
        (state: CompanyState, action: PayloadAction<any>) => {
          return {
            ...state,
            profile: action.payload,
            isLoading: false,
          };
        }
      )
      .addCase(fetchAsyncGetCompanyProf.pending, (state: CompanyState) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(fetchAsyncGetCompanyProf.rejected, () => {
        window.location.href = "/error?e_code=company_01";
      })

      .addCase(
        fetchAsyncGetCompanyLaw.fulfilled,
        (state: CompanyState, action: PayloadAction<any>) => {
          return {
            ...state,
            law: action.payload,
            isLoading: false,
          };
        }
      )
      .addCase(fetchAsyncGetCompanyLaw.pending, (state: CompanyState) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(fetchAsyncGetCompanyLaw.rejected, () => {
        window.location.href = "/error?e_code=company_02";
      })

      .addCase(fetchAsyncGetTos.fulfilled, (state: CompanyState, action: PayloadAction<any>) => {
        return {
          ...state,
          tos: action.payload,
        };
      })
      .addCase(fetchAsyncGetTos.rejected, () => {
        window.location.href = "/error?e_code=company_03";
      });
  },
});

export const selectIsLoading = (state: RootState) => state.company.isLoading;
export const selectCompanyProfile = (state: RootState) => state.company.profile;
export const selectCompanyLaw = (state: RootState) => state.company.law;
export const selectCompanyTos = (state: RootState) => state.company.tos;
export default companySlice.reducer;
