import { FC, useEffect } from "react";

import { PayJpInfo } from "common/types";
import { PAYJP_PUBLIC_KEY } from "common/constants";

import "styles/Button.css";
import "styles/PayCardBtn.css";

type Props = {
  onAfterCreated: (token: string) => void;
  isUpdateMode?: boolean;
};

/**
 * クレジットカード登録モーダルを開くボタンコンポーネント
 * @returns
 */
const PayCardBtn: FC<Props> = ({ onAfterCreated, isUpdateMode = false }) => {
  /** payjpスクリプト読み込み */
  useEffect(() => {
    /** カード情報登録完了後に発火する関数 */
    const onCreated = (card: PayJpInfo) => {
      onAfterCreated(card.id);
    };

    const nandoyaPayCard = document.getElementById("nandoya-pay-card");
    const script = document.createElement("script");

    script.src = "https://checkout.pay.jp";
    script.setAttribute("class", "payjp-button");
    script.setAttribute("data-key", PAYJP_PUBLIC_KEY);
    script.setAttribute("data-partial", "true");
    script.setAttribute("data-text", `クレジットカード${isUpdateMode ? "更新" : "登録"}`);
    script.setAttribute("data-submit-text", "登録");
    script.setAttribute("data-on-created", "onCreated");

    // @ts-ignore
    window.onCreated = onCreated;

    script.async = true;
    nandoyaPayCard?.appendChild(script);

    return () => {
      nandoyaPayCard?.removeChild(script);
    };
  }, [isUpdateMode, onAfterCreated]);

  return <div id="nandoya-pay-card" className="flex justify-center mt-12 nandoya-btn"></div>;
};

export default PayCardBtn;
