import { FC, memo, ReactNode, useMemo, ChangeEvent } from "react";

import { addNDates, formatDate, formatDateofJp } from "common/common";

type Props = {
  setDate: Function;
};

/**
 * 契約開始希望日セレクト
 * @param Props
 * @returns
 */
const StartDateSelect: FC<Props> = ({ setDate }) => {
  /** 年Option */
  const options: ReactNode[] = useMemo(() => {
    const options: ReactNode[] = [
      <option key={999} value="" hidden>
        選択してください
      </option>,
    ];
    for (let i = 0; i < 5; i++) {
      const date: Date = addNDates(10 + i);
      options.push(
        <option key={i} value={formatDate(date)}>
          {formatDateofJp(date)}
        </option>
      );
    }
    return options;
  }, []);

  return (
    <div className="mx-auto">
      <label className="block mb-[-6px]">契約開始希望日</label>
      <small className="text-red-500">※10~14日後からのみ選択可能です。</small>
      <select
        className="w-full rounded-lg"
        onChange={(e: ChangeEvent<HTMLSelectElement>) => setDate(e.target.value)}
      >
        {options}
      </select>
    </div>
  );
};

export default memo(StartDateSelect);
