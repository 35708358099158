import { FC, memo, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchAsyncGetTos, selectCompanyTos } from "ducks/company/slice";
import { CompanyTos } from "ducks/company/type";

type Props = {
  isAgree: boolean;
  checkTos: VoidFunction;
};

/**
 * 利用規約
 * @param Props
 * @returns
 */
const Tos: FC<Props> = ({ isAgree, checkTos }) => {
  const dispatch = useAppDispatch();
  const companyTos: CompanyTos = useAppSelector(selectCompanyTos);

  useEffect(() => {
    dispatch(fetchAsyncGetTos());
  }, [dispatch]);

  return (
    <>
      {companyTos && (
        <>
          <div className="border-2 border-gray-600 h-48 w-11/12 max-w-3xl mx-auto mt-10 overflow-y-scroll sm:h-72">
            <div
              dangerouslySetInnerHTML={{ __html: companyTos.tos }}
              className="[&_h1]:font-bold [&_h2]:font-bold [&_h3]:font-bold [&_h4]:font-bold [&_h5]:font-bold"
            ></div>
          </div>

          <div className="mt-6 text-center">
            <label className="cursor-pointer">
              <input
                className="mr-2 cursor-pointer"
                type="checkbox"
                checked={isAgree}
                onChange={checkTos}
              />
              利用規約に同意する
            </label>
          </div>
        </>
      )}
    </>
  );
};

export default memo(Tos);
