import { ChangeEvent, FC, memo, ReactNode, useEffect, useMemo, useState } from "react";

import { useAppDispatch } from "app/hooks";
import { MAX_NAME_LENGTH, MAX_TRUNK_ITEM_NUM } from "common/constants";
import Input from "components/common/Input";
import Button from "components/common/Button";

import { Trunk, TrunkItem, TrunkItems } from "ducks/trunkItem/type";
import { fetchAsyncPostTrunkItem } from "ducks/trunkItem/slice";

type Props = {
  trunks: Trunk[];
  trunkItems: TrunkItems;
  getTrunkItems: Function;
};

/**
 * 収納物登録コンポーネント
 * @returns
 */
const ItemRegister: FC<Props> = ({ trunks, trunkItems, getTrunkItems }) => {
  const dispatch = useAppDispatch();

  const [itemName, setItemName] = useState<string>("");
  const [itemImg, setItemImg] = useState<File | null>(null);
  const [notificationMonth, setNotificationMonth] = useState<string>("");
  const [trunkNumber, setTrunkNumber] = useState<string>("");
  const [isDisabledBtn, setIsDisabledBtn] = useState<boolean>(false);
  const [isMaxItemErr, setIsMaxItemErr] = useState<boolean>(false);
  const [isNameLenErr, setIsNameLenErr] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  /** input初期化 */
  const initInput = () => {
    setItemName("");
    setItemImg(null);
    setNotificationMonth("");
    setTrunkNumber("");
  };

  /** 通知月Option */
  const notificationMonthOptions: ReactNode[] = useMemo(() => {
    const options: ReactNode[] = [
      <option key={999} value="" hidden>
        選択
      </option>,
    ];
    for (let i = 1; i <= 12; i++) {
      options.push(
        <option key={i} value={i}>
          {i}月
        </option>
      );
    }
    return options;
  }, []);

  /** トランク番号Option */
  const trunkNumberOptions: ReactNode[] = useMemo(() => {
    const options: ReactNode[] = [
      <option key={999} value="" hidden>
        選択
      </option>,
    ];
    trunks.forEach((trunk: Trunk, index: number) => {
      options.push(
        <option key={index} value={trunk.trunkNumber}>
          {trunk.trunkNumber}
        </option>
      );
    });
    return options;
  }, [trunks]);

  /** 名前入力 */
  const changeItemName = (e: ChangeEvent<HTMLInputElement>) => {
    const value: string = e.target.value;
    if (value.length <= MAX_NAME_LENGTH) {
      setIsNameLenErr(false);
    } else {
      setIsNameLenErr(true);
    }
    setItemName(value);
  };

  /** 写真登録 */
  const changeItemImg = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const fileList: FileList = e.target.files;
      const files: File[] = Array.from(fileList);
      setItemImg(files[0]);
    }
  };

  /** 通知月セレクト */
  const changeNotificationMonth = (e: ChangeEvent<HTMLSelectElement>) => {
    const value: string = e.target.value;
    setNotificationMonth(value);
  };

  /** トランク番号セレクト */
  const changeTrunkNumber = (e: ChangeEvent<HTMLSelectElement>) => {
    const value: string = e.target.value;
    setTrunkNumber(value);
    setIsMaxItemErr(false);
  };

  /** 登録ボタン押下 */
  const clickRegistration = async () => {
    if (itemImg && notificationMonth) {
      const targetTrunk: Trunk | undefined = trunks.find(
        (trunk: Trunk) => trunk.trunkNumber === trunkNumber
      );
      if (targetTrunk) {
        // 既に登録されている収納物数が、最大数を超える場合はエラーメッセージを表示
        let itemNum: number = 0;
        trunkItems.forEach((item: TrunkItem) => {
          item.trunkNumber === targetTrunk.trunkNumber && itemNum++;
        });

        if (itemNum < MAX_TRUNK_ITEM_NUM) {
          setIsMaxItemErr(false);
          setIsUploading(true);
          await dispatch(
            fetchAsyncPostTrunkItem({
              body: {
                apartmentNumber: targetTrunk.trunkNumber.slice(0, 3),
                trunkNumber: targetTrunk.trunkNumber,
                ownerEmail: targetTrunk.contractorEmail,
                name: itemName,
                // リクエスト時に動的に設定
                image: "",
                notificationMonth: Number(notificationMonth),
              },
              img: itemImg,
            })
          );

          await getTrunkItems();
          initInput();
          setIsUploading(false);
        } else {
          setIsMaxItemErr(true);
        }
      }
    }
  };

  /** 入力項目監視 */
  useEffect(() => {
    if (
      itemName &&
      itemName.length <= MAX_NAME_LENGTH &&
      itemImg !== null &&
      notificationMonth &&
      trunkNumber
    ) {
      setIsDisabledBtn(false);
    } else {
      setIsDisabledBtn(true);
    }
  }, [itemImg, itemName, notificationMonth, trunkNumber]);

  return (
    <div className="border border-black rounded-md mx-2 p-4 pt-0 mb-4 md:flex relative">
      <label className="block absolute top-[-12px] left-2 bg-white px-4 font-semibold">
        収納物の登録
      </label>

      <div className="[&>div]:m-4">
        <Input
          label="名前"
          type="text"
          id="trunkItemName"
          value={itemName}
          isError={isNameLenErr}
          errMsg={`${MAX_NAME_LENGTH}文字まで入力可能です`}
          maxLength={MAX_NAME_LENGTH}
          onChange={changeItemName}
        />

        <div>
          <p className="text-sm font-medium text-black sm:text-base">画像</p>
          <label className="block p-2 hover:opacity-70 text-white bg-main rounded-md shadow-md cursor-pointer">
            <input
              className="hidden"
              type="file"
              accept="image/*"
              onChange={changeItemImg}
              onClick={(e: any) => {
                // 同じファイルを指定した場合にも登録できる用の処理
                e.target.value = "";
              }}
            />
            写真を選択
          </label>
          <p>{itemImg?.name}</p>
        </div>

        <div className="grid grid-cols-2 gap-4 [&_label]:text-sm [&_label]:font-medium [&_label]:text-black [&_label]:sm:text-base [&_select]:w-full [&_select]:rounded-lg">
          <div>
            <label>通知月</label>
            <select value={notificationMonth} onChange={changeNotificationMonth}>
              {notificationMonthOptions}
            </select>
          </div>
          <div>
            <label>トランク番号</label>
            <select value={trunkNumber} onChange={changeTrunkNumber}>
              {trunkNumberOptions}
            </select>
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center md:items-end mb-4 [&>div>button]:mt-6">
        <div>
          {isMaxItemErr && (
            <p className="text-red-500 w-56">1トランク当たりに登録できる件数は8件までです</p>
          )}
          <Button onClick={clickRegistration} disabled={isDisabledBtn} isLoading={isUploading}>
            登録
          </Button>
        </div>
      </div>
    </div>
  );
};

export default memo(ItemRegister);
