import { ChangeEvent, FC, memo, useState } from "react";

import { useAppDispatch } from "app/hooks";
import { setIdImgs, setIdImgSrcs } from "ducks/auth/slice";

import "styles/Button.css";
import { readAsDataURL } from "common/common";

type Props = {
  idImgs: File[];
};

/**
 * 本人確認書類登録ボタン
 * @returns
 */
const IdImgsUploadBtn: FC<Props> = ({ idImgs }) => {
  const dispatch = useAppDispatch();

  const [isError, setIsError] = useState<boolean>(false);

  /** 写真登録 */
  const changeIdImgs = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setIsError(false);
      const fileList: FileList = e.target.files;
      const files: File[] = Array.from(fileList);
      if (files.length > 2) {
        setIsError(true);
      } else {
        dispatch(setIdImgs({ files }));

        // 表示用をストアに保存
        const tmp: string[] = [];
        for (const file of files) {
          const res = await readAsDataURL(file);
          tmp.push(res);
        }
        dispatch(setIdImgSrcs(tmp));
      }
    }
  };

  return (
    <div>
      <div className="flex justify-center items-center mb-2">
        <label className="nandoya-btn flex justify-center p-0">
          <input className="hidden" type="file" accept="image/*" onChange={changeIdImgs} multiple />
          {idImgs.length > 0 ? "✔︎ 本人確認書類を登録済み" : "本人確認書類をアップロード"}
        </label>
      </div>

      {isError && <p className="text-red-500 text-sm text-center">※登録できる写真は2枚までです</p>}

      <div className="flex justify-center mx-auto w-10/12 text-sm ">
        <p>例）</p>
        <p>
          運転免許証（両面）、健康保険証（両面）、パスポート、マイナンバーカードなどの住所の記載が確認できるもの
        </p>
      </div>
    </div>
  );
};

export default memo(IdImgsUploadBtn);
