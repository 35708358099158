import { FC, useMemo } from "react";

import { PAGES } from "common/PAGES";

type Path = {
  name: string;
  path: string;
};

/**
 * 共通フッター
 * @returns
 */
const Footer: FC = () => {
  const year = useMemo(() => new Date().getFullYear(), []);
  const paths: Path[] = useMemo(() => {
    return [
      {
        name: PAGES.COMPANY_PROFILE.NAME,
        path: PAGES.COMPANY_PROFILE.PATH,
      },
      {
        name: PAGES.COMPANY_LAW.NAME,
        path: PAGES.COMPANY_LAW.PATH,
      },
    ];
  }, []);

  return (
    <footer className="w-full h-32 bg-main text-slate-50 text-center pt-6 sm:pt-6">
      <ul className="text-center [&>li]:pt-1 [&>li>a]:cursor-pointer sm:flex sm:justify-end sm:[&>li]:pr-16">
        {paths.map((path: Path, index: number) => {
          return (
            <li key={index}>
              <a
                className="hover:border-b-2 text-slate-50 hover:border-slate-50"
                href={path.path}
                target="_blank"
                rel="noreferrer"
              >
                {path.name}
              </a>
            </li>
          );
        })}
      </ul>

      <small className="block mt-2 sm:mt-10 sm:text-[16px]">
        All rights reserved. Kamiya associate inc. &copy; {year}
      </small>
    </footer>
  );
};

export default Footer;
