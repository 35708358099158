import { FC, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "app/hooks";
import Layout from "components/Layout";
import TwoColContents from "components/common/TwoColContents";
import Title from "components/common/Title";
import ContentCard from "components/common/ContentCard";
import Loader from "components/common/Loader";

import { fetchAsyncGetCompanyLaw, selectCompanyLaw, selectIsLoading } from "ducks/company/slice";
import { CompanyLaw } from "ducks/company/type";

type Props = {
  title: string;
};

/**
 * 特商法について画面
 * @param Props
 * @returns
 */
const Law: FC<Props> = ({ title }) => {
  const dispatch = useAppDispatch();
  const isLoading: boolean = useAppSelector(selectIsLoading);
  const companyLaw: CompanyLaw = useAppSelector(selectCompanyLaw);

  useEffect(() => {
    dispatch(fetchAsyncGetCompanyLaw());
  }, [dispatch]);

  return (
    <Layout title={title}>
      {companyLaw.contents.length === 0 || isLoading ? (
        <Loader isActive={isLoading}></Loader>
      ) : (
        <div className="pb-16">
          <Title>特定商取引法に基づく表記</Title>

          <ContentCard>
            <TwoColContents rows={companyLaw.contents} />
          </ContentCard>
        </div>
      )}
    </Layout>
  );
};

export default Law;
