import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "app/hooks";
import { addNYears } from "common/common";
import { PAGES } from "common/PAGES";
import { CONTRACT_PERIOD, LOCAL_STORAGE_KEYS } from "common/constants";
import { TwoColRow } from "components/common/TwoColContents";
import { selectIdImgs, selectIdImgSrcs, selectUserInfo } from "ducks/auth/slice";
import { UserInfo } from "ducks/auth/type";
import {
  selectIsRendering,
  selectTargetTrunk,
  unsetIsRendering,
  selectIsLoading,
  fetchAsyncPutTrunkApplication,
  fetchAsyncGetTrunksByTrunkNumber,
} from "ducks/trunk/slice";
import { TrunkData } from "ducks/trunk/type";

/**
 * トランク申込み確認画面用フックス
 * @returns
 */
export const useTrunkApplication = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userInfo: UserInfo = useAppSelector(selectUserInfo);
  const targetTrunk: TrunkData | null = useAppSelector(selectTargetTrunk);
  const idImgs: File[] = useAppSelector(selectIdImgs);

  const isLoading: boolean = useAppSelector(selectIsLoading);
  const isRendering: boolean = useAppSelector(selectIsRendering);
  const idImgSrcs: string[] = useAppSelector(selectIdImgSrcs);

  const [rows, setRows] = useState<TwoColRow[]>([]);
  const [startDatedAt, setStartDatedAt] = useState<string>("");
  const [isOpenImgsModal, setIsOpenImgsModal] = useState<boolean>(false);
  const [cardToken, setCardToken] = useState<string>("");

  /** 申込むボタン */
  const clickApply = useCallback(async () => {
    if (targetTrunk) {
      await dispatch(
        fetchAsyncPutTrunkApplication({
          trunkBody: {
            trunkNumber: targetTrunk.trunkNumber,
            contractorEmail: userInfo.email,
            contractStartedAt: startDatedAt,
            contractEndedAt: addNYears(startDatedAt, CONTRACT_PERIOD),
          },
          emailBody: {
            userName: userInfo.userName,
            userNameKana: userInfo.userNameKana,
            email: userInfo.email,
            apartmentName: targetTrunk.apartmentName,
            trunkNumber: targetTrunk.trunkNumber,
            sectionNumber: targetTrunk.sectionNumber,
            birthdate: userInfo.birthdate,
            address: userInfo.address,
            phoneNumber: userInfo.phoneNumber,
          },
          idImgs,
          cardBody: {
            email: userInfo.email,
            token: cardToken,
          },
        })
      );
    }
  }, [dispatch, idImgs, startDatedAt, targetTrunk, userInfo, cardToken]);

  /** 戻るボタン */
  const clickBack = useCallback(() => {
    if (targetTrunk) {
      navigate(-1);
    }
  }, [navigate, targetTrunk]);

  /** 写真確認モーダルクローズ */
  const closeImgsModal = useCallback(() => {
    setIsOpenImgsModal(false);
  }, []);

  /** 契約対象トランク情報取得 */
  useEffect(() => {
    const trunkNumber: string | null = localStorage.getItem(LOCAL_STORAGE_KEYS.TRUNK_NUMBER);
    if (trunkNumber) {
      dispatch(fetchAsyncGetTrunksByTrunkNumber(trunkNumber));
    } else {
      navigate(PAGES.MYPAGE_TOP.PATH);
    }
  }, [dispatch, navigate]);

  /** データ整形 */
  useEffect(() => {
    if (targetTrunk) {
      setRows([
        {
          title: "マンション名",
          content: targetTrunk.apartmentName,
        },
        {
          title: "区画番号",
          content: targetTrunk.sectionNumber,
        },
        {
          title: "トランク番号",
          content: targetTrunk.trunkNumber,
        },
        {
          title: "氏名",
          content: userInfo.userName,
        },
        {
          title: "氏名（カナ）",
          content: userInfo.userNameKana,
        },
        {
          title: "生年月日",
          content: userInfo.birthdate,
        },
        {
          title: "部屋番号",
          content: userInfo.address,
        },
        {
          title: "電話番号",
          content: userInfo.phoneNumber,
        },
        {
          title: "メールアドレス",
          content: userInfo.email,
        },
      ]);
    }
  }, [dispatch, navigate, userInfo, targetTrunk]);

  /** 契約登録POSTが完了したらページ遷移 */
  useEffect(() => {
    if (isRendering) {
      dispatch(unsetIsRendering());
      localStorage.removeItem(LOCAL_STORAGE_KEYS.TRUNK_NUMBER);
      navigate(PAGES.TRUNK_APPLICATION_END.PATH);
    }
  }, [dispatch, navigate, isRendering]);

  return {
    userInfo,
    rows,
    isLoading,
    startDatedAt,
    idImgs,
    isOpenImgsModal,
    idImgSrcs,
    cardToken,
    setIsOpenImgsModal,
    setStartDatedAt,
    setCardToken,
    clickApply,
    clickBack,
    closeImgsModal,
  };
};
