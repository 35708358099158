import { FC, memo } from "react";
import { Dialog } from "@headlessui/react";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

import Modal from "components/common/Modal";
import Button from "components/common/Button";

import { TrunkItem } from "ducks/trunkItem/type";

type Props = {
  isOpen: boolean;
  item: TrunkItem | null;
  isDeleting: boolean;
  close: VoidFunction;
  exeDelete: VoidFunction;
};

/**
 * 収納物削除の確認モーダル
 * @returns
 */
const DelCheckModal: FC<Props> = ({ isOpen, item, isDeleting, close, exeDelete }) => {
  return (
    <Modal isOpen={isOpen}>
      <div>
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full">
          <ExclamationCircleIcon className="h-12 w-12 text-red-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
            本当に削除しますか？
          </Dialog.Title>

          {item && (
            <ul className="[&>li]:text-gray-500">
              <li className="!text-black">{item.name}</li>
              <li>{item.notificationMonth}月</li>
              <li>{item.trunkNumber}</li>
            </ul>
          )}
        </div>
      </div>

      <div className="mt-5 sm:flex sm:flex-row-reverse [&>button]:mb-4 [&>button]:h-12 [&>button_img]:h-8">
        <Button onClick={exeDelete} isLoading={isDeleting}>
          削除
        </Button>
        <Button onClick={close} disabled={isDeleting} subMode>
          キャンセル
        </Button>
      </div>
    </Modal>
  );
};

export default memo(DelCheckModal);
