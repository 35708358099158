import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * SPA遷移時にスクロール位置が残る問題の対処
 * @returns
 */
const ScrollTop: FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default ScrollTop;
