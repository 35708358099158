import { FC, useMemo } from "react";

import { useAppDispatch, useAppSelector } from "app/hooks";
import Layout from "components/Layout";
import Title from "components/common/Title";
import ContentCard from "components/common/ContentCard";
import PayCardBtn from "components/mypage/PayCardBtn";
import TwoColContents, { TwoColRow } from "components/common/TwoColContents";
import Loader from "components/common/Loader";
import { fetchAsyncPostCards, selectIsLoading, selectUserInfo } from "ducks/auth/slice";
import { UserInfo } from "ducks/auth/type";

type Props = {
  title: string;
};

/**
 * 契約者情報画面
 * @param Props
 * @returns
 */
const ContractorDetail: FC<Props> = ({ title }) => {
  const dispatch = useAppDispatch();

  const isLoading: boolean = useAppSelector(selectIsLoading);
  const userInfo: UserInfo = useAppSelector(selectUserInfo);
  const rows: TwoColRow[] = useMemo(() => {
    return [
      {
        title: "マンション",
        content: userInfo.apartmentName,
      },
      {
        title: "氏名",
        content: userInfo.userName,
      },
      {
        title: "氏名（カナ）",
        content: userInfo.userNameKana,
      },
      {
        title: "生年月日",
        content: userInfo.birthdate,
      },
      {
        title: "部屋番号",
        content: userInfo.address,
      },
      {
        title: "電話番号",
        content: userInfo.phoneNumber,
      },
      {
        title: "メールアドレス",
        content: userInfo.email,
      },
      {
        title: "クレジットカード登録",
        content: userInfo.isAuthedCard ? "あり" : "なし",
      },
    ];
  }, [userInfo]);

  const onAfterCreated = (token: string) => {
    dispatch(fetchAsyncPostCards({ email: userInfo.email, token }));
  };

  return (
    <Layout title={title}>
      {isLoading ? (
        <Loader isActive={isLoading}></Loader>
      ) : (
        <div className="pb-16">
          <Title>契約者情報</Title>

          <ContentCard>
            <TwoColContents rows={rows} />
          </ContentCard>

          <PayCardBtn onAfterCreated={onAfterCreated} isUpdateMode={userInfo.isAuthedCard} />
        </div>
      )}
    </Layout>
  );
};

export default ContractorDetail;
