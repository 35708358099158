import { FC, memo, useMemo } from "react";

import { Apartment } from "ducks/trunk/type";

type row = {
  title: string;
  content: string;
};

type Props = {
  apartment: Apartment;
  isMansionName?: boolean;
};

/**
 * マンション情報
 * @param Props
 * @returns
 */
const MansionInfo: FC<Props> = ({ apartment, isMansionName }) => {
  const rows: row[] = useMemo(() => {
    return [
      {
        title: "広さ",
        content: `${apartment.size}帖`,
      },
      {
        title: "幅×奥行き×高さ",
        content: `${apartment.width.toFixed(1)}m×${apartment.depth.toFixed(
          1
        )}m×${apartment.height.toFixed(1)}m`,
      },
      {
        title: "床耐重量",
        content: `${apartment.maxWeight}kg`,
      },
      {
        title: "事務手数料",
        content: `${apartment.handlingFee}円`,
      },
      {
        title: "クリーニング代",
        content: `${apartment.deposit}円`,
      },
      {
        title: "月額使用料",
        content: `${apartment.price}円`,
      },
    ];
  }, [apartment]);

  return (
    <div className="overflow-hidden rounded-lg shadow max-w-xl mx-auto mt-8 border-2 border-black w-11/12 sm:w-3/5">
      <ul className="px-2 pb-2 whitespace-pre-wrap break-words [&>li]:flex [&>li]:mt-2 [&>li>p]:w-1/2 [&>li]:justify-center [&>li]:items-center">
        {isMansionName && (
          <li>
            <p className="text-center">マンション名</p>
            <p className="sm:ml-12">{apartment.apartmentName}</p>
          </li>
        )}
        {rows.map((row: row, index: number) => {
          return (
            <li key={index}>
              <p className="text-center">{row.title}</p>
              <p className="sm:ml-12">{row.content}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default memo(MansionInfo);
