import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { API } from "aws-amplify";
import axios from "axios";

import { compressImg, getTimestampNow } from "common/common";
import {
  EX_AGW_NAME,
  AGW_PATHS,
  IN_AGW_NAME,
  CONTRACT_STATUS,
  EMAIL_TYPE,
  BUCKET_DIR_NAMES,
} from "common/constants";
import { PAGES } from "common/PAGES";
import { GetApartmentsRes, GetPutSignedUrlsRes, GetTrunksRes } from "common/types";
import { initialState } from "ducks/trunk/initialState";
import { TrunkData, TrunkState } from "ducks/trunk/type";

/**
 * マンション情報の取得
 * @param qParams クエリパラメータ
 **/
export const fetchAsyncGetApartments = createAsyncThunk(
  "trunk/getApartments",
  async (qParams: { [key: string]: string }, thunkAPI) => {
    const params = {
      queryStringParameters: qParams,
    };
    try {
      return await API.get(EX_AGW_NAME, AGW_PATHS.APARTMENTS, params);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**
 * マンションのトランク情報の取得
 * @param qParams クエリパラメータ
 **/
export const fetchAsyncGetTrunksByAptNumber = createAsyncThunk(
  "trunk/getTrunksByAptNumber",
  async (qParams: { [key: string]: string }, thunkAPI) => {
    const params = {
      queryStringParameters: qParams,
    };
    try {
      return await API.get(EX_AGW_NAME, AGW_PATHS.TRUNKS, params);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**
 * 特定のトランク情報を取得
 * @param trunkNumber トランク番号
 **/
export const fetchAsyncGetTrunksByTrunkNumber = createAsyncThunk(
  "trunk/getTrunksByTrunkNumber",
  async (trunkNumber: string, thunkAPI) => {
    const params = {
      queryStringParameters: {
        trunkNumber,
      },
    };
    try {
      return await API.get(EX_AGW_NAME, AGW_PATHS.TRUNKS, params);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**
 * 契約済みのマンション情報の取得
 * @param qParams クエリパラメータ
 **/
export const fetchAsyncGetTrunksByEmail = createAsyncThunk(
  "trunk/getTrunksByEmail",
  async (qParams: { [key: string]: string }, thunkAPI) => {
    const params = {
      queryStringParameters: qParams,
    };
    try {
      return await API.get(IN_AGW_NAME, AGW_PATHS.TRUNKS, params);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**
 * マンション利用申請
 * @param args 利用申請情報
 **/
export const fetchAsyncPutTrunkApplication = createAsyncThunk(
  "trunk/putTrunkApplication",
  async (
    args: {
      trunkBody: {
        trunkNumber: string;
        contractorEmail: string;
        contractStartedAt: string;
        contractEndedAt: string;
      };
      emailBody: {
        userName: string;
        userNameKana: string;
        email: string;
        apartmentName: string;
        trunkNumber: string;
        sectionNumber: string;
        birthdate: string;
        address: string;
        phoneNumber: string;
      };
      idImgs: File[];
      cardBody: {
        email: string;
        token: string;
      };
    },
    thunkAPI
  ) => {
    // 本当に対象のトランクが未契約状態かを再度確認
    const checkTrunkRes: GetTrunksRes = await API.get(EX_AGW_NAME, AGW_PATHS.TRUNKS, {
      queryStringParameters: { trunkNumber: args.trunkBody.trunkNumber },
    });

    if (checkTrunkRes.data[0].trunkCurrentStatus !== CONTRACT_STATUS.NO_CONTRACT) {
      window.location.href = PAGES.TRUNK_APPLICATION_ERROR.PATH;
      return false;
    }

    try {
      // 初回申請時のみ本人確認書類を登録
      if (args.idImgs.length > 0) {
        const idDocNames: string[] = [];
        args.idImgs.forEach((img: File, index: number) => {
          const fileType: string | undefined = img.name.split(".").pop();
          idDocNames.push(
            `${BUCKET_DIR_NAMES.USER_ID}/${args.trunkBody.contractorEmail}-id-doc-${index}.${fileType}`
          );
        });

        // ユーザーエンティティ更新
        await API.put(IN_AGW_NAME, AGW_PATHS.USERS, {
          body: {
            email: args.trunkBody.contractorEmail,
            idDocNames,
          },
        });

        // S3に本人確認書類を保存
        const res: GetPutSignedUrlsRes = await API.get(IN_AGW_NAME, AGW_PATHS.PUT_SIGNED_URLS, {
          queryStringParameters: {
            fileNames: idDocNames.join(","),
          },
        });

        await Promise.all(
          args.idImgs.map(async (img: File, index: number) => {
            const options = {
              headers: {
                "Content-Type": img.type,
              },
            };
            // ファイルを圧縮して登録
            const compressedImg: File = await compressImg(img);
            await axios.put(res.data[0].putSignedUrls[index], compressedImg, options);
          })
        );
      }

      // カード登録
      if (args.cardBody.token) {
        await API.post(IN_AGW_NAME, AGW_PATHS.CARDS, {
          body: {
            email: args.cardBody.email,
            token: args.cardBody.token,
          },
        });
      }

      // トランクエンティティ更新
      await API.put(IN_AGW_NAME, AGW_PATHS.TRUNKS, {
        body: {
          ...args.trunkBody,
          trunkCurrentStatus: CONTRACT_STATUS.REVIEW,
        },
      });

      // メール送信
      await API.post(IN_AGW_NAME, AGW_PATHS.SEND_EMAILS, {
        body: { ...args.emailBody, type: EMAIL_TYPE.CONTRACT_REQUEST },
      });

      return true;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**
 * マンション退去申請
 * @param body 退去申請情報
 **/
export const fetchAsyncPutLeaveApplication = createAsyncThunk(
  "trunk/putLeaveApplication",
  async (
    args: {
      trunkBody: { trunkNumber: string; contractEndedAt: string };
      emailBody: {
        userName: string;
        userNameKana: string;
        email: string;
        apartmentName: string;
        trunkNumber: string;
        contractEndedAt: string;
      };
    },
    thunkAPI
  ) => {
    const params = {
      body: {
        ...args.trunkBody,
        trunkCurrentStatus: CONTRACT_STATUS.PLAN_TO_CANCEL,
        // 退去完了写真情報の初期化
        latestImages: {
          email: "",
          uploadedAt: 0,
          fileNames: [],
        },
      },
    };
    try {
      // トランクエンティティ更新
      await API.put(IN_AGW_NAME, AGW_PATHS.TRUNKS, params);
      // メール送信
      await API.post(IN_AGW_NAME, AGW_PATHS.SEND_EMAILS, {
        body: { ...args.emailBody, type: EMAIL_TYPE.CANCEL_REQUEST },
      });
      return {};
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**
 * トランク退去画像アップロード
 * @param args
 **/
export const fetchAsyncPutLeavePictures = createAsyncThunk(
  "trunk/putLeavePictures",
  async (
    args: {
      trunkNumber: string;
      email: string;
      imgs: File[];
    },
    thunkAPI
  ) => {
    try {
      const fileNames: string[] = [];
      args.imgs.forEach((img: File, index: number) => {
        const fileType: string | undefined = img.name.split(".").pop();
        fileNames.push(
          `${BUCKET_DIR_NAMES.LEAVE_TRUNK}/${args.trunkNumber}-leave-doc-${index}.${fileType}`
        );
      });

      // ユーザーエンティティ更新
      await API.put(IN_AGW_NAME, AGW_PATHS.TRUNKS, {
        body: {
          trunkNumber: args.trunkNumber,
          latestImages: {
            email: args.email,
            uploadedAt: getTimestampNow(),
            fileNames,
          },
        },
      });

      // S3に本人確認書類を保存
      const res: GetPutSignedUrlsRes = await API.get(IN_AGW_NAME, AGW_PATHS.PUT_SIGNED_URLS, {
        queryStringParameters: {
          fileNames: fileNames.join(","),
        },
      });

      await Promise.all(
        args.imgs.map(async (img: File, index: number) => {
          const options = {
            headers: {
              "Content-Type": img.type,
            },
          };
          // ファイルを圧縮して登録
          const compressedImg: File = await compressImg(img);
          await axios.put(res.data[0].putSignedUrls[index], compressedImg, options);
        })
      );
      return {};
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/** トランクスライス */
export const trunkSlice = createSlice({
  name: "trunk",
  initialState,
  reducers: {
    setTargetTrunk(state: TrunkState, action: PayloadAction<TrunkData>) {
      return {
        ...state,
        targetTrunk: action.payload,
      };
    },
    unsetTargetTrunk(state: TrunkState) {
      return {
        ...state,
        targetTrunk: null,
      };
    },
    unsetIsRendering(state: TrunkState) {
      return {
        ...state,
        isRendering: false,
      };
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<TrunkState>) => {
    builder
      .addCase(
        fetchAsyncGetApartments.fulfilled,
        (state: TrunkState, action: PayloadAction<GetApartmentsRes>) => {
          if (action.payload.data.length > 0) {
            return {
              ...state,
              apartment: action.payload.data[0],
            };
          }
        }
      )
      .addCase(fetchAsyncGetApartments.rejected, () => {
        window.location.href = "/error?e_code=trunk_01";
      })

      .addCase(
        fetchAsyncGetTrunksByAptNumber.fulfilled,
        (state: TrunkState, action: PayloadAction<GetTrunksRes>) => {
          return {
            ...state,
            trunk: action.payload,
            nextToken: action.payload.lastEvaluatedKey.sortKey
              ? JSON.stringify(action.payload.lastEvaluatedKey)
              : "",
            isLoading: false,
          };
        }
      )
      .addCase(fetchAsyncGetTrunksByAptNumber.pending, (state: TrunkState) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(fetchAsyncGetTrunksByAptNumber.rejected, () => {
        window.location.href = "/error?e_code=trunk_02";
      })

      .addCase(
        fetchAsyncGetTrunksByTrunkNumber.fulfilled,
        (state: TrunkState, action: PayloadAction<GetTrunksRes>) => {
          if (action.payload.data.length > 0) {
            return {
              ...state,
              targetTrunk: action.payload.data[0],
              isLoading: false,
            };
          }
        }
      )
      .addCase(fetchAsyncGetTrunksByTrunkNumber.pending, (state: TrunkState) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(fetchAsyncGetTrunksByTrunkNumber.rejected, () => {
        window.location.href = "/error?e_code=trunk_03";
      })

      .addCase(
        fetchAsyncGetTrunksByEmail.fulfilled,
        (state: TrunkState, action: PayloadAction<GetTrunksRes>) => {
          return {
            ...state,
            contractedTrunk: action.payload,
            nextToken: action.payload.lastEvaluatedKey.sortKey
              ? JSON.stringify(action.payload.lastEvaluatedKey)
              : "",
            isLoading: false,
          };
        }
      )
      .addCase(fetchAsyncGetTrunksByEmail.pending, (state: TrunkState) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(fetchAsyncGetTrunksByEmail.rejected, () => {
        window.location.href = "/error?e_code=trunk_04";
      })

      .addCase(
        fetchAsyncPutTrunkApplication.fulfilled,
        (state: TrunkState, action: PayloadAction<boolean>) => {
          if (action.payload) {
            return {
              ...state,
              isLoading: false,
              isRendering: true,
            };
          }
        }
      )
      .addCase(fetchAsyncPutTrunkApplication.pending, (state: TrunkState) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(fetchAsyncPutTrunkApplication.rejected, () => {
        window.location.href = "/error?e_code=trunk_05";
      })

      .addCase(fetchAsyncPutLeaveApplication.fulfilled, (state: TrunkState) => {
        return {
          ...state,
          isLoading: false,
          isRendering: true,
        };
      })
      .addCase(fetchAsyncPutLeaveApplication.pending, (state: TrunkState) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(fetchAsyncPutLeaveApplication.rejected, () => {
        window.location.href = "/error?e_code=trunk_06";
      })

      .addCase(fetchAsyncPutLeavePictures.fulfilled, () => {})
      .addCase(fetchAsyncPutLeavePictures.rejected, () => {
        window.location.href = "/error?e_code=trunk_07";
      });
  },
});

export const { setTargetTrunk, unsetTargetTrunk, unsetIsRendering } = trunkSlice.actions;

export const selectIsLoading = (state: RootState) => state.trunk.isLoading;
export const selectIsRendering = (state: RootState) => state.trunk.isRendering;

export const selectApartment = (state: RootState) => state.trunk.apartment;
export const selectTrunk = (state: RootState) => state.trunk.trunk;
export const selectTargetTrunk = (state: RootState) => state.trunk.targetTrunk;
export const selectContractedTrunk = (state: RootState) => state.trunk.contractedTrunk;
export const selectNextToken = (state: RootState) => state.trunk.nextToken;

export default trunkSlice.reducer;
