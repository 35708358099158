import { PAGES } from "common/PAGES";

/** ヘッダーロゴ */
export const HEADER_LOGO = `${process.env.PUBLIC_URL}/icons/header-logo.svg`;
/** デフォルトサムネ */
export const DEFAULT_THUMBNAIL = `${process.env.PUBLIC_URL}/icons/default-thumbnail.svg`;
/** マンション写真なしアイコン */
export const DEFAULT_MANSION_ICON = `${process.env.PUBLIC_URL}/icons/no-mansion-icon.svg`;
/** ボタンローディングアイコン */
export const BTN_LOADING = `${process.env.PUBLIC_URL}/icons/btn-loading.svg`;

/** PAYJPパブリックキー */
export const PAYJP_PUBLIC_KEY = process.env.REACT_APP_PAYJP_PUBLIC_KEY as string;

/** 非公開AGW名 */
export const IN_AGW_NAME = "InternalAPIGatewayAPI";
/** 公開AGW名 */
export const EX_AGW_NAME = "ExternalAPIGatewayAPI";

/** AGWパス名 */
export const AGW_PATHS = {
  USERS: "/users",
  APARTMENTS: "/apartments",
  TRUNKS: "/trunks",
  LIST_APARTMENT_NAMES: "/listApartmentNames",
  CARDS: "/cards",
  PUT_SIGNED_URLS: "/putSignedURLs",
  SEND_EMAILS: "/sendEmails",
  TRUNKS_ITEMS: "/trunks/items",
} as const;

/** microCMS APIドメイン */
export const CMS_API_DOMAIN = process.env.REACT_APP_CMS_API_DOMAIN as string;

/** microCMS APIキー (GETのみ可能) */
export const CMS_API_KEY = process.env.REACT_APP_CMS_API_KEY as string;

/** microCMS パス */
export const CMS_API_PATHS = {
  COMPANY: "company",
  LAW: "law",
  TOS: "tos",
} as const;

/** ログインなしでアクセス可能なパスリスト */
export const FREE_ACCESS_PATHS: readonly string[] = [
  PAGES.TRUNK_DETAIL.PATH,
  PAGES.COMPANY_PROFILE.PATH,
  PAGES.COMPANY_LAW.PATH,
];

/** URLパスパラメータ */
export const URL_PARAMS = {
  TRUNK_NUMBER: ":trunkNumber",
} as const;

/** クエリパラメータ */
export const Q_PARAMS = {
  APARTMENT_NUMBER: "aptNum",
  PAGE_TOKENS: "pageToks",
} as const;

export const LOCAL_STORAGE_KEYS = {
  TRUNK_NUMBER: "trunkNum",
} as const;

/** 契約状況 */
export const CONTRACT_STATUS = {
  NO_CONTRACT: 0,
  REVIEW: 1,
  CONTRACTED: 2,
  PLAN_TO_CANCEL: 3,
} as const;

export const CONTRACT_STATUS_STRS: readonly string[] = ["未契約", "審査中", "契約中", "解約予定"];

/** 契約期間（年） */
export const CONTRACT_PERIOD: number = 2;

/** メール送信タイプ */
export const EMAIL_TYPE = {
  CONTRACT_REQUEST: "contract_request",
  CANCEL_REQUEST: "cancel_request",
};

/** S3バケット内フォルダ名 */
export const BUCKET_DIR_NAMES = {
  APARTMENT: "apartment",
  LEAVE_TRUNK: "leaveTrunk",
  USER_ID: "userId",
  TRUNK_ITEM: "trunkItem",
};

/** 1トランク当たりの最大登録件数 */
export const MAX_TRUNK_ITEM_NUM: number = 8;

/** 収納物名称の最大 */
export const MAX_NAME_LENGTH: number = 20;

/** 退去完了写真の最大登録枚数 */
export const MAX_LEAVE_IMG_MAX: number = 3;
